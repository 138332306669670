import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as H from 'history';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, IconButton } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import { joinClassNames } from '../../services/elementHelperService';
import { GridMediasDisplay } from '../../reusable/MediaDisplay/GridMediasDisplay';
import { mediaNames, mediaNamesQuery, mediaTypes } from '../../constants/mediaConstants';
import { defaultPaginationRoutePath } from '../../constants/paginationConstant';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../constants/routesPath';
import { useFetchLibraryData } from '../../hooks/screens/library/useFetchLibraryData';

import { DashboardUtils } from './DashboardUtils';
import { buildRoutePath } from '../../utils/commonUtil';

const { DASHBOARD_BASE_CLASSNAME, DASHBOARD_LABELS, RecentMediaTypes, useButtonCustomStyle, EmptyMedias } =
	DashboardUtils;

interface RecentMediasProps {
	history: H.History;
	mediaType?: string;
	hidePagination?: boolean;
	onShortcutActionClick?: () => void;
	onMediaActionMenuClick?: (_mediaDetails: any, _action: string) => void; // params: mediaDetails, actionId
}

const RecentMedias = ({
	mediaType = RecentMediaTypes.video as string,
	history,
	hidePagination,
	onShortcutActionClick,
	onMediaActionMenuClick,
}: RecentMediasProps) => {
	const { t } = useTranslation();
	const classes = useButtonCustomStyle();
	const accountId = useSelector((state: any) => state.session.defaultAccountId);

	const [offset, setOffset] = useState<number>(0);

	const { mediaFilters, mediaName, searchQuery } = useMemo(() => {
		let filters = {};
		let type = mediaNames.medias;
		let searchQuery = `q=(${mediaNamesQuery.noEditedMedias})`;

		switch (mediaType) {
			case RecentMediaTypes.video:
				filters = {
					[mediaTypes.video]: true,
				};
				break;
			case RecentMediaTypes.live:
				filters = {
					[mediaTypes.livevideo]: true,
				};
				break;
			case RecentMediaTypes.interact:
				type = mediaNames.scenarios;
				searchQuery = '';
				break;
			case RecentMediaTypes.edits:
				type = mediaNames.editedMedias;
				searchQuery = '';
				break;
			default:
				filters = {};
				type = mediaNames.medias;
				searchQuery = `q=(${mediaNamesQuery.noEditedMedias})`;
		}

		return {
			mediaFilters: filters,
			mediaName: type,
			searchQuery: searchQuery,
		};
	}, [mediaType]);

	const limit = useMemo(() => (onShortcutActionClick ? 4 : 5), [onShortcutActionClick]);

	const { items, thumbnails } = useFetchLibraryData({
		offset: offset * limit,
		limit: limit,
		mediaFilters,
		mediaType: mediaName,
		searchQuery,
		shouldFetchThumbnails: true
	});

	const onMediaItemClick = useCallback(
		(mediaDetail: any) => {
			let url = `${screenRoutes.PUBLISH_MEDIA_LIBRARY}/medias/${mediaDetail.id}/detail`;
			switch (mediaType) {
				case RecentMediaTypes.interact:
					localStorage.setItem('startingPoint', 'INTERACT');
					url = `${screenRoutes.PUBLISH_SCENARIOS}/${mediaDetail.id}/detail`;
					break;
				case RecentMediaTypes.edits:
					localStorage.setItem('startingPoint', 'EDIT');
					break;
				case RecentMediaTypes.live:
					localStorage.setItem('startingPoint', 'LIVE');
					break;
				default:
					localStorage.setItem('startingPoint', 'ALL_MEDIA');
			}
			history.push(buildRoutePath(url, ACCOUNT_ID_ROUTE_PLACEHOLDER, accountId));
		},
		[history, mediaType, accountId]
	);

	const goToLibrary = useCallback(() => {
		let route = `${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`;
		switch (mediaType) {
			case RecentMediaTypes.interact:
				route = `${screenRoutes.PUBLISH_SCENARIOS}${defaultPaginationRoutePath}`;
				break;
			case RecentMediaTypes.edits:
				route = `${screenRoutes.PUBLISH_EDITED_MEDIA}${defaultPaginationRoutePath}`;
				break;
			case RecentMediaTypes.live:
				route = `${screenRoutes.PUBLISH_LIVE_MEDIA}${defaultPaginationRoutePath}`;
				break;
			default:
				route = `${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`;
		}
		history.push(buildRoutePath(route, ACCOUNT_ID_ROUTE_PLACEHOLDER, accountId));
	}, [history, mediaType, accountId]);

	useEffect(() => {
		setOffset((prevOffset) => (prevOffset === 0 ? prevOffset : 0));
	}, [accountId]);

	return (
		<div
			className={joinClassNames(
				`${DASHBOARD_BASE_CLASSNAME}__container`,
				`${DASHBOARD_BASE_CLASSNAME}__container-medias`
			)}
		>
			<div className={`${DASHBOARD_BASE_CLASSNAME}__container-title`}>
				<div>{t((DASHBOARD_LABELS as any)[mediaType].recentListTitle)}</div>
				{(items.length > 0 || offset !== 0) && (
					<div className={`${DASHBOARD_BASE_CLASSNAME}__container-action`}>
						<div className={`${DASHBOARD_BASE_CLASSNAME}__link`} onClick={goToLibrary}>
							{t('DASHBOARD_RECENT_MEDIAS_VIEW_ALL_BUTTON_LABEL')}
						</div>
						{!hidePagination && (
							<ButtonGroup className={classes.customButtonGroup}>
								<IconButton
									title={t('COMMON_PREVIOUS')}
									className={classes.customButton}
									disabled={offset === 0}
									onClick={() =>
										setOffset((prevOffset: number) => (prevOffset === 0 ? 0 : prevOffset - 1))
									}
								>
									<NavigateBefore />
								</IconButton>

								<IconButton
									title={t('COMMON_NEXT')}
									className={classes.customButton}
									disabled={items.length < limit}
									onClick={() => setOffset((prevOffset: number) => prevOffset + 1)}
								>
									<NavigateNext />
								</IconButton>
							</ButtonGroup>
						)}
					</div>
				)}
			</div>

			<GridMediasDisplay
				classNames={`${DASHBOARD_BASE_CLASSNAME}__media-grid`}
				medias={items}
				thumbnails={thumbnails}
				isMiniLibrary
				onMediaItemClick={onMediaItemClick}
				onMediaActionMenuClick={onMediaActionMenuClick}
				onShortcutActionClick={onShortcutActionClick}
				shortcutLabel={t((DASHBOARD_LABELS as any)[mediaType].shortcutLabel)}
				emptyContainer={
					<EmptyMedias
						message={
							offset === 0
								? t((DASHBOARD_LABELS as any)[mediaType].emptyMessage)
								: t((DASHBOARD_LABELS as any)[mediaType].noMoreMedia)
						}
						subMessage={offset === 0 ? t((DASHBOARD_LABELS as any)[mediaType].emptySubMessage) : ''}
						baseClassName={`${DASHBOARD_BASE_CLASSNAME}__container-medias`}
					/>
				}
			/>
		</div>
	);
};

export default RecentMedias;
