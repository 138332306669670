import React, { useEffect, useState } from 'react';

import chevron_down from '../../../../../assets/images/chevron-down.svg';
import right_arrow from '../../../../../assets/images/right_arrow.svg';
import refresh from '../../../../../assets/images/arrow-counterclockwise.svg';
import lock_fill from '../../../../../assets/images/lock-fill.svg';
import no_medias_icon from '../../../../../assets/images/no_medias.svg';
import delete_icon from '../../../../../assets/images/delete.svg';
import star from '../../../../../assets/images/star.svg';
import star_select from '../../../../../assets/images/star_select.svg';

import { joinClassNames } from '../../../../../services/elementHelperService';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from '@material-ui/core';
import {
	PLAYBACK_INITIATE_PLAYER_ON,
	PLAYBACK_INITIATE_PLAYER_ON_GRID_VIEW,
	playerKeys,
	portalThemeSettingType,
	stylingKeys,
	stylingKeysNeedToBeHex,
	translationKeys,
} from '../../../../../constants/portalThemesConstant';
import ColorPicker from '../../../../../reusable/ColorPicker/ColorPicker';
import { useTranslation } from 'react-i18next';
import useThemes, { Theme } from './hooks/useThemes';
import useExpendedSection, { SECTION_DEFAULT_THEMES } from './hooks/useExpandSection';
import LoadingOverlay from 'react-loading-overlay';
import { ScaleLoader } from 'react-spinners';
import { isEqual } from 'lodash';

import './PortalThemes.scss';
import ConfirmationDialog from '../../../../../reusable/ConfirmationDialog';
import usePlayerConfiguration from './hooks/usePlayerConfiguration';
import { portalsUrl } from '../../../../../utils/config';
import { Close } from '@material-ui/icons';
import { defaultSettingTypes } from '../../../../../constants/playerConfigurationsConstant';

type PortalCreationDialogProps = {
	isOpen: boolean;
	editingTheme: Theme;
	saveAsNewTheme: (_isSaveNewDefaultTheme: boolean, _themeName: string, _callback: () => void) => void;
	onClose: () => void;
};

const PortalCreationDialog = ({ isOpen, editingTheme, saveAsNewTheme, onClose }: PortalCreationDialogProps) => {
	const [themeName, setThemeName] = useState<string>('');

	useEffect(() => {
		setThemeName(`${editingTheme.name}`);
	}, [editingTheme]);

	return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">Save as new theme</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div className="dialogContentWrapper">
					<div className="dialogContent__item">
						<label>Name of theme</label>
						<input
							value={themeName}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setThemeName(event.target.value);
							}}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
						<Button
							type="button"
							variant="contained"
							className="defaultActionBtn"
							onClick={() => {
								saveAsNewTheme(false, themeName, onClose);
							}}
						>
							Save
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

const PortalTheme = () => {
	const { t } = useTranslation();

	const {
		loadingData,
		themesToRender,
		editingThemeId,
		userEditingThemeChanged,
		updatingEditingThemeId,
		updatingUserChange,
		saveChangeTheme,
		saveAsNewTheme,
		resetDefaultTheme,
		deleteATheme,
		toggleThemeFavorite,
	} = useThemes();

	const { playerConfigurationToRender } = usePlayerConfiguration();

	const [deleteThemeId, setDeleteThemeId] = useState<string>('');
	const [isShowCreationDialog, setIsShowCreationDialog] = useState<boolean>(false);

	const { expandedSection, updateExpandSection } = useExpendedSection();
	const {
		defaultThemes: defaultThemesLoading,
		accountThemes: accountThemesLoading,
		defaultThemesId: defaultThemesIdLoading,
	} = loadingData;

	const showLoadingOverlay = defaultThemesLoading || accountThemesLoading || defaultThemesIdLoading;
	const isDefaultThemesSectionExpands = expandedSection.includes(SECTION_DEFAULT_THEMES);
	const defaultThemesSectionItems = themesToRender.filter((theme) => theme.isDefault);
	const accountThemes = themesToRender.filter((theme) => !theme.isDefault);
	const originalEditingTheme = themesToRender.find((theme) => theme.themeId === editingThemeId);

	if (showLoadingOverlay) {
		return (
			<LoadingOverlay
				className="loadingStreamOverlay"
				active={true}
				spinner={<ScaleLoader color={'#ffffff'} />}
				text={'Loading data...'}
			/>
		);
	}

	if (!userEditingThemeChanged) {
		return (
			<div className="portalThemesWrapper">
				<div className="portalThemesWrapper__no-data">
					<img src={no_medias_icon} />
					<label> {t('MEDIA_LIBRARY_MEDIA_DISPLAY_THERE_IS_NOTHING_HERE')}</label>
				</div>
			</div>
		);
	}

	const { name, isDefault, styling } = userEditingThemeChanged;
	const accountPlayerPreset = playerConfigurationToRender.filter((config) => !config.isDefault);

	return (
		<div className="portalThemesWrapper">
			<div className="configurationThemes">
				<div className="configurationThemes__header">Portals layouts</div>

				<div
					className="configurationThemes__sub-header"
					onClick={() => {
						updateExpandSection('defaultThemes');
					}}
				>
					<span>Choose a layout</span>
					<img
						className={joinClassNames([
							'playerConfigurations-arrow',
							isDefaultThemesSectionExpands ? 'playerConfigurations-arrow--expanded' : '',
						])}
						src={isDefaultThemesSectionExpands ? chevron_down : right_arrow}
					/>
				</div>

				{isDefaultThemesSectionExpands && (
					<>
						<div className="configurationThemes__default-themes">
							{defaultThemesSectionItems.map(({ themeId, name }) => {
								const isSelected = editingThemeId === themeId;
								return (
									<div
										key={themeId}
										className={`theme-item ${isSelected ? 'theme-item--selected' : ''}`}
										onClick={() => {
											updatingEditingThemeId(themeId);
										}}
									>
										<Typography variant="h6" color="textSecondary" style={{ color: '#ffffff' }}>
											{name}
										</Typography>
										<span>Create presets from this layout</span>
									</div>
								);
							})}
						</div>
						<div className="configurationThemes__saved-themes">
							<label>Saved presets</label>
							<FormControl
								fullWidth
								className={`saved-theme-select__control ${
									accountThemes.find((theme) => theme.themeId === editingThemeId) ? 'selected' : ''
								}`}
							>
								<Select
									id="saved-theme-selector"
									value={
										themesToRender.find(
											({ isDefault, themeId }) => !isDefault && themeId === editingThemeId
										)
											? editingThemeId
											: 0
									}
									onChange={(
										event: React.ChangeEvent<{
											name?: string;
											value: unknown;
										}>
									) => {
										updatingEditingThemeId(event.target.value as string);
									}}
									disableUnderline
								>
									<MenuItem disabled value={0} className="saved-themes-option">
										{accountThemes.length === 0 ? 'No custom layouts' : 'Choose a layout to edit'}
									</MenuItem>
									{accountThemes.map(({ themeId, name, favorite }) => {
										return (
											<MenuItem key={themeId} value={themeId} className="saved-themes-option">
												<div className="saved-themes-option__content">
													{name}
													<div className="saved-themes-option__action">
														<button
															type="button"
															className="saved-themes-option--star"
															onClick={(event) => {
																event.stopPropagation();
																toggleThemeFavorite(themeId);
															}}
														>
															<img src={favorite ? star_select : star} />
														</button>
														<button
															type="button"
															className="saved-themes-option--delete"
															onClick={(event) => {
																event.stopPropagation();
																setDeleteThemeId(themeId);
															}}
														>
															<img src={delete_icon} />
														</button>
													</div>
												</div>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					</>
				)}

				<div className="configurationTheme-editing-title sub-header">
					<span>Editing: {name ?? 'Unknown'}</span>
				</div>

				<div className="configurationTheme-editing-name borderBottom">
					<span>Name of preset</span>
					<div className="configurationTheme__input">
						<input
							value={name}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const value = e.target.value;
								updatingUserChange('name', value);
							}}
							disabled={isDefault}
						/>
						{isDefault && (
							<div className="configurationThemes_tooltip-container">
								<label className="tooltiptext">
									{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_PRESET_NAME_LOCK')}
								</label>
								<img src={lock_fill} />
							</div>
						)}
					</div>
				</div>

				<div className="configurationThemes-notice">See change in preview window (right)</div>

				<div className="configurationTheme-cards">
					{Object.values(portalThemeSettingType)
						.sort(
							(keyA: string, keyB: string) =>
								(portalThemeSettingType as any)[keyA] - (portalThemeSettingType as any)[keyB]
						)
						.map((settingKey: any) => {
							return (
								<div key={settingKey} className="configurationTheme-card">
									<div className="configurationTheme-card__header" onClick={() => {}}>
										{translationKeys[settingKey as keyof typeof translationKeys]}
										{isDefault && (
											<div className={'playerConfigurations-card__header-actions'}>
												<button
													className="playerConfigurations-card__refresh-btn"
													onClick={() => {
														resetDefaultTheme(settingKey);
													}}
												>
													<img src={refresh} /> {t('SETTINGS_PRESET_CONFIG_RESET_TO_DEFAULT')}
												</button>
												<img
													className={joinClassNames([
														'playerConfigurations-arrow',
														'playerConfigurations-arrow--expanded',
													])}
													src={chevron_down}
												/>
											</div>
										)}
									</div>
									<div className="configurationTheme-card__content">
										{settingKey === portalThemeSettingType.Styling && (
											<FormGroup>
												{stylingKeys.map((key) => {
													if (stylingKeysNeedToBeHex.includes(key)) {
														return (
															<div key={key} className="configurationTheme__control">
																<label
																	id={key + '-select-label'}
																	htmlFor={key + '-select'}
																>
																	{
																		translationKeys[
																			key as keyof typeof translationKeys
																		]
																	}
																</label>
																<div className="playerConfigurations-card__color-picker">
																	<span>Color</span>
																	<ColorPicker
																		width="20px"
																		height="20px"
																		borderRadius="4px"
																		padding="2px"
																		color={
																			userEditingThemeChanged.styling?.[key] ??
																			'#ffffff'
																		}
																		onChangeComplete={(color: any) => {
																			updatingUserChange(
																				`${settingKey}.${key}`,
																				color.hex
																			);
																		}}
																	/>
																</div>
															</div>
														);
													}

													return (
														<div key={key} className="configurationTheme__control">
															<label id={key + '-select-label'} htmlFor={key + '-select'}>
																{translationKeys[key as keyof typeof translationKeys]}
															</label>
															<FormControlLabel
																labelPlacement="start"
																control={
																	<OutlinedInput
																		className="configurationTheme__input"
																		value={
																			userEditingThemeChanged.styling?.[key] ?? ''
																		}
																		onChange={(
																			event: React.ChangeEvent<
																				HTMLTextAreaElement | HTMLInputElement
																			>
																		) => {
																			const value = event.target.value;
																			updatingUserChange(
																				`${settingKey}.${key}`,
																				value
																			);
																		}}
																	/>
																}
																label={null}
															/>
														</div>
													);
												})}
											</FormGroup>
										)}

										{settingKey === portalThemeSettingType.Playback && (
											<FormGroup>
												{playerKeys.map((key) => {
													if (key === 'defaultPlayerPreset') {
														return (
															<div key={key} className="configurationTheme__control">
																<label
																	id={key + '-select-label'}
																	htmlFor={key + '-select'}
																>
																	{
																		translationKeys[
																			key as keyof typeof translationKeys
																		]
																	}
																</label>

																<Select
																	labelId={'player-config-select-label'}
																	id={'player-config-select'}
																	value={
																		userEditingThemeChanged.playback?.[key] ?? ''
																	}
																	disableUnderline
																	style={{ marginTop: '6px' }}
																	onChange={(
																		event: React.ChangeEvent<{
																			name?: string;
																			value: unknown;
																		}>
																	) => {
																		const value = event.target.value as string;
																		updatingUserChange(
																			`${settingKey}.${key}`,
																			value
																		);
																	}}
																>
																	<MenuItem
																		key={0}
																		value={0}
																		className="player-config-select-option"
																		disabled
																	>
																		<>
																			{t(
																				'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_CHOOSE_PLAYER'
																			)}
																		</>
																	</MenuItem>
																	<div className="player-config-select__subheader">
																		{t(
																			'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_DEFAULT_PRESETS'
																		)}
																	</div>
																	{playerConfigurationToRender
																		.filter((config) => config.isDefault)
																		.map((config, index) => {
																			return (
																				<MenuItem
																					key={index}
																					value={config.id}
																					className="player-config-select-option"
																				>
																					<div className="player-config-select-option__default-label">
																						<div className="player-config-select-option__primary-text">
																							{
																								defaultSettingTypes[
																									config.id.toLowerCase() as keyof typeof defaultSettingTypes
																								].name
																							}
																						</div>
																						<div className="player-config-select-option__secondary-text">
																							{t(
																								defaultSettingTypes[
																									config.id.toLowerCase() as keyof typeof defaultSettingTypes
																								].note
																							)}
																						</div>
																					</div>
																				</MenuItem>
																			);
																		})}
																	{accountPlayerPreset.length > 0 && <Divider />}
																	{accountPlayerPreset.length > 0 && (
																		<div className="player-config-select__subheader">
																			{t(
																				'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_SAVED_PRESETS'
																			)}
																		</div>
																	)}
																	{playerConfigurationToRender.map(
																		(config, index) => {
																			if (config.isDefault) {
																				return null;
																			}
																			return (
																				<MenuItem
																					key={index}
																					value={config.id}
																					className="player-config-select-option"
																				>
																					{config.name}
																				</MenuItem>
																			);
																		}
																	)}
																</Select>
															</div>
														);
													}

													if (key === 'initiatePlayerOn') {
														return (
															<div key={key} className="configurationTheme__control">
																<label
																	id={key + '-select-label'}
																	htmlFor={key + '-select'}
																>
																	{
																		translationKeys[
																			key as keyof typeof translationKeys
																		]
																	}
																</label>
																<Select
																	value={
																		userEditingThemeChanged.playback?.[key] ||
																		PLAYBACK_INITIATE_PLAYER_ON_GRID_VIEW
																	}
																	disableUnderline
																	style={{ marginTop: '6px' }}
																	onChange={(
																		event: React.ChangeEvent<{
																			name?: string;
																			value: unknown;
																		}>
																	) => {
																		const value = event.target.value as string;
																		updatingUserChange(
																			`${settingKey}.${key}`,
																			value
																		);
																	}}
																>
																	{PLAYBACK_INITIATE_PLAYER_ON.map((type) => {
																		return (
																			<MenuItem
																				key={type}
																				value={type}
																				className="player-config-select-option"
																			>
																				<>
																					{
																						translationKeys[
																							type as keyof typeof translationKeys
																						]
																					}
																				</>
																			</MenuItem>
																		);
																	})}
																</Select>
															</div>
														);
													}
												})}
											</FormGroup>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
			<div className="previewThemes">
				<iframe
					width={'100%'}
					height={'100%'}
					src={`${portalsUrl}preview?theme=${encodeURIComponent(JSON.stringify(styling))}`}
				/>
			</div>

			{!isEqual(originalEditingTheme, userEditingThemeChanged) && (
				<div className="portal-theme-actions">
					<span className="portal-theme-actions__change-notice">
						{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_CHANGE_NOTICE')}
					</span>
					{!userEditingThemeChanged.isDefault && (
						<Button
							type="button"
							variant="contained"
							className="defaultActionBtn squireBtn"
							onClick={saveChangeTheme}
						>
							{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_SAVE_CHANGE_BTN')}
						</Button>
					)}

					<Button
						type="button"
						variant="contained"
						className="defaultActionBtn squireBtn"
						onClick={() => {
							setIsShowCreationDialog(true);
						}}
					>
						{t('SETTINGS_PRESET_CONFIG_PLAYER_CONFIG_SAVE_AS_PRESET_BTN')}
					</Button>
				</div>
			)}

			{deleteThemeId && (
				<ConfirmationDialog
					title={t('SETTINGS_PRESET_CONFIG_PRESET_DELETE_CONFIRMATION_TITLE')}
					message={t('SETTINGS_PRESET_CONFIG_PRESET_DELETE_CONFIRMATION_MESSAGE')}
					callback={({ ok }) => {
						if (ok) {
							deleteATheme(deleteThemeId);
						}
						setDeleteThemeId('');
					}}
					okButtonText={t('COMMON_DELETE')}
				/>
			)}

			<PortalCreationDialog
				isOpen={isShowCreationDialog}
				editingTheme={userEditingThemeChanged}
				saveAsNewTheme={saveAsNewTheme}
				onClose={() => {
					setIsShowCreationDialog(false);
				}}
			/>
		</div>
	);
};

export default PortalTheme;
