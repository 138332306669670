export const portalThemeSettingType = {
	Styling: 'styling',
	Playback: 'playback',
};

export const portalThemeSettingTypeOrder = {
	styling: 1,
	playback: 2,
};

export const stylingKeys = [
	'logoType',
	'backgroundColor',
	'gridBackgroundColor',
	'textColor',
	'videoFrameColor',
	'buttonColor',
	'buttonTextColor',
];

export const stylingKeysNeedToBeHex = [
	'backgroundColor',
	'gridBackgroundColor',
	'textColor',
	'videoFrameColor',
	'buttonColor',
	'buttonTextColor',
];

export const playerKeys = ['defaultPlayerPreset', 'initiatePlayerOn'];

export const PLAYBACK_INITIATE_PLAYER_ON_GRID_VIEW = 'gridView';
export const PLAYBACK_INITIATE_PLAYER_ON_MEDIA_VIEW = 'mediaView';
export const PLAYBACK_INITIATE_PLAYER_ON = [
	PLAYBACK_INITIATE_PLAYER_ON_GRID_VIEW,
	PLAYBACK_INITIATE_PLAYER_ON_MEDIA_VIEW,
];

export const translationKeys = {
	styling: 'Styling',
	playback: 'Playback',
	logoType: 'Logotype',
	backgroundColor: 'Background Color',
	gridBackgroundColor: 'Grid Background Color',
	defaultPlayerPreset: 'Default Player Preset',
	textColor: 'Text Color',
	videoFrameColor: 'Video Frame Color',
	buttonColor: 'Button Color',
	buttonTextColor: 'Button Text Color',
	initiatePlayerOn: 'Initiate Player On',
	gridView: 'Grid View',
	mediaView: 'Media View',
};

// qbrick-portal-2.0 also have the same if you change here, you need to change there as well
export const INDIVIDUAL_SHARE_PORTAL_ID = 'q6e2m03b';
