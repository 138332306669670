import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import './TabContent.scss';
import { Box, List, ListItem } from '@material-ui/core';
import { useListCustomStyle } from '../../CustomStyle/useCustomStyle';
import { joinClassNames } from '../../../services/elementHelperService';
import { useTranslation } from 'react-i18next';
import MediaItem from './mediaItem/MediaItem';
import Pagination from '../../Pagination/Pagination';
import { mediaTypes } from '../../../constants/mediaConstants';

type InteractLibraryProps = {
	medias: { id: string; thumbnail: string; description: string }[];
	totalCount: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (_e: object, _page: number) => void;
	onRowPerPageChange: (_e: any) => void;
	setAllowToSubmit: (_e: boolean) => void;
};

const InteractLibrary = forwardRef(
	(
		{
			medias,
			totalCount,
			page,
			rowsPerPage,
			onPageChange,
			onRowPerPageChange,
			setAllowToSubmit,
		}: InteractLibraryProps,
		ref
	) => {
		const { t: translator } = useTranslation();
		const classes = useListCustomStyle();
		const [selectedMedia, setSelectedMedia] = useState<any>();

		const submitSelectedMedia = useCallback(() => {
			return [selectedMedia];
		}, [selectedMedia]);

		useImperativeHandle(ref, () => {
			return { submitSelectedMedia };
		});

		return (
			<Box className="media-library-modal-wrapper">
				<Box className="structure-navigator">
					<List>
						<ListItem
							key={'interact'}
							button
							classes={{ selected: classes.selected }}
							style={{ borderRadius: '4px', marginBottom: '5px' }}
							className={joinClassNames(undefined, classes.customItem)}
						>
							<span className={classes.ellipsis}>{translator('COMMON_MEDIA_TYPE_INTERACT')}</span>
						</ListItem>
					</List>
				</Box>
				<Box className="content-wrapper">
					<Box className="media-list">
						{medias.map((media: any) => {
							return (
								<MediaItem
									currentMediaType={mediaTypes.scenario}
									translator={translator}
									key={media.id}
									id={media.id}
									media={media}
									selected={selectedMedia?.id === media.id}
									onMediaSelectionChange={(media) => {
										setSelectedMedia(media);
										setAllowToSubmit(true);
									}}
								/>
							);
						})}
					</Box>
					<Pagination
						count={totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage={`${translator('COMMON_MEDIAS_PER_PAGE')}:`}
						onPageChange={onPageChange}
						onRowsPerPageChange={onRowPerPageChange}
					/>
				</Box>
			</Box>
		);
	}
);

export default InteractLibrary;
