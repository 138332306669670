import React, { useEffect, useState } from 'react';

import {
	Dialog,
	DialogTitle,
	Typography,
	IconButton,
	DialogActions,
	Button,
	Divider,
	Box,
	TextField,
	CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Theme } from '../InspectScreen/InspectSections/PortalThemes/hooks/useThemes';
import { fetchAccountPortalThemesService } from '../../../services/portalApiService';
import { LocalStorageService } from '../../../services/localStorageService';
import portalThemeThumbnail from '../../../assets/images/portal-theme-thumbnail.png';
import { createNewPortal } from '../../../services/mediaService';
import { generateUUID } from '../../../utils/commonUtil';
import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';
import { useDispatch } from 'react-redux';
import { _getAccountSettings } from '../../../services/profileServices';

type ThemeItemsProps = {
	themes: Theme[];
	selectedTheme: Theme | null;
	setSelectedTheme: (_themeId: Theme) => void;
};

const ThemeItems = ({ themes, selectedTheme, setSelectedTheme }: ThemeItemsProps) => {
	return (
		<Box style={{ display: 'flex', flexWrap: 'wrap', rowGap: 15, columnGap: 37 }}>
			{themes.map((theme) => (
				<Box
					key={theme.themeId}
					style={{
						width: 266,
						height: 278,
						border: `2px solid ${selectedTheme?.themeId === theme.themeId ? '#126CFC' : '#CDD4D9'}`,
						borderRadius: 4,
						padding: 10,
						cursor: 'pointer',
						transition: 'border 0.3s',
					}}
					onClick={() => setSelectedTheme(theme)}
				>
					<Box>
						<img src={portalThemeThumbnail} alt={theme.name} style={{ width: '100%' }} />
					</Box>
					<Typography variant="body1">{theme.name}</Typography>
				</Box>
			))}
		</Box>
	);
};

type CreatePortalDialogProps = {
	open: boolean;
	onClose: (_isNeedToRefresh?: boolean) => void;
};

const CreatePortalDialog = ({ open, onClose }: CreatePortalDialogProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const defaultAccountId = LocalStorageService.getDefaultAccountId();

	const [themes, setThemes] = useState<Theme[]>([]);
	const [portalName, setPortalName] = useState<string>('');
	const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const handleCloseDialog = (isNeedToRefresh: boolean = false) => {
		onClose?.(isNeedToRefresh);
		setPortalName('');
		setSelectedTheme(null);
	};

	const handleCreatePortal = async () => {
		setIsSubmitting(true);

		const newPortalPayload = {
			id: generateUUID(),
			themeId: selectedTheme?.themeId,
			content: { name: portalName },
		};

		try {
			if (!defaultAccountId) {
				throw new Error('No default account id');
			}
			const result = await createNewPortal(defaultAccountId, newPortalPayload);

			if (!result) {
				dispatch(showMessage(t('Failed to create portal'), messageTypes.error));
				return;
			}

			handleCloseDialog(true);
			dispatch(showMessage(t('Portal created successfully'), messageTypes.success));
		} catch (e) {
			dispatch(showMessage(t('Failed to create portal'), messageTypes.error));
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		if (!defaultAccountId) {
			return;
		}

		const fetchCreatedThemes = async () => {
			const result = await fetchAccountPortalThemesService({ accountId: defaultAccountId });

			if (!result) {
				setThemes([]);
				return;
			}

			setThemes(result.sort((a, b) => a.name.localeCompare(b.name)));
			return;
		};

		fetchCreatedThemes();
	}, [defaultAccountId]);

	return (
		<Dialog
			className="create-scenario-dialog"
			open={open}
			maxWidth="md"
			onClose={() => {
				handleCloseDialog();
			}}
			fullWidth
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('Create a new portal')}</Typography>
				<IconButton
					onClick={() => {
						handleCloseDialog();
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>

			<div className="dialogContentWrapper">
				<Box style={{ paddingBottom: 10 }}>
					<Typography variant="body1" style={{ fontWeight: 'bold' }}>
						{t('Name your portal')}
					</Typography>
				</Box>
				<Box style={{ width: 270 }}>
					<TextField
						value={portalName}
						placeholder={t('MODAL_CREATE_NEW_CATALOG_TITLE_OF_CATALOG_PLACEHOLDER')}
						variant="outlined"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setPortalName(event.target.value);
						}}
						size="small"
						fullWidth
					/>
				</Box>
			</div>

			<Divider />

			<div className="dialogContentWrapper" style={{ overflowY: 'scroll' }}>
				{themes.length === 0 && (
					<Box style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Typography variant="body1" style={{ fontWeight: 'bold' }}>
							{t(
								'There are currently no presets setup on your account. Please contact your account admin for more information.'
							)}
						</Typography>
					</Box>
				)}
				{themes.length > 0 && (
					<>
						<Box style={{ paddingBottom: 10 }}>
							<Typography variant="body1" style={{ fontWeight: 'bold' }}>
								{t('Choose a preset')}
							</Typography>
						</Box>

						<ThemeItems themes={themes} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
					</>
				)}
			</div>

			<DialogActions style={{ borderTop: '1px solid #CDD4D9' }}>
				<Button
					variant="contained"
					onClick={handleCreatePortal}
					color="primary"
					disabled={!portalName || !selectedTheme || isSubmitting}
					startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
				>
					{t('Create')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreatePortalDialog;
