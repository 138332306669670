import { useCallback, useEffect, useRef } from 'react';
import { LocalStorageService } from '../../services/localStorageService';
import { useDispatch, useSelector } from 'react-redux';
import {
	checkLoginStatus,
	logoutUserSession,
	sendCustomMetadataRequest,
	sessionEnd,
	sessionInit,
} from '../../actions/sessionActions';
import { TokenService } from '../../services/tokenService';
import { getAccountFeatures } from '../../actions/accountFeaturesActions';
import { sessionActivityEvents } from '../../constants/eventsConstants';

export const useCheckLogin = (accountId: string, notLoginCallback: () => void) => {
	const dispatch = useDispatch() as any;
	const sessionTimer = useRef<any | undefined>(undefined);

	const { isLoggedIn } = useSelector((state) => (state as any).session);

	const checkLogin = useCallback(() => {
		if (LocalStorageService.getAccessToken()) {
			dispatch(checkLoginStatus())
				.then((response: any) => {
					if (response === undefined || (response && !response.isValid)) {
						clearSession();
					} else {
						initializeSession();
						if (accountId) {
							dispatch(getAccountFeatures(accountId));
							dispatch(sendCustomMetadataRequest(accountId));
						}
					}
				})
				.catch((error: any) => error);
		} else {
			notLoginCallback();
		}
	}, [history, accountId]);

	const clearSession = useCallback(() => {
		dispatch(sessionEnd());
		LocalStorageService.clearLocalStorage();
		notLoginCallback();
	}, []);

	const initializeSession = useCallback(() => {
		TokenService.validateLocalToken();
		const userId = LocalStorageService.getUserId() ?? '';
		const userPermissions = LocalStorageService.getUserPermissions() ?? '';
		const accounts = LocalStorageService.getAccounts()
			? JSON.parse(LocalStorageService.getAccounts() ?? '')
			: LocalStorageService.getAccounts();
		const username = LocalStorageService.getUserName() ?? '';
		const defaultAccountId = LocalStorageService.getDefaultAccountId() ?? '';
		const defaultAccountName = LocalStorageService.getDefaultAccountName() ?? '';
		const accessToken = LocalStorageService.getAccessToken();

		const sessionObjectToReInitialize = {
			userId: userId,
			userPermissions: userPermissions,
			accounts: accounts,
			username: username,
			defaultAccountId: defaultAccountId,
			defaultAccountName: defaultAccountName,
			accessToken: accessToken,
		};
		dispatch(sessionInit(sessionObjectToReInitialize));
	}, []);

	const checkSessionExpired = useCallback(
		(clear = false) => {
			if (sessionTimer && sessionTimer.current) {
				clearTimeout(sessionTimer.current);
			}
			if (isLoggedIn && !clear) {
				const time = LocalStorageService.getExpiresIn();
				if (time) {
					const timeInNumber = Number(time);
					sessionTimer.current = setTimeout(() => {
						dispatch(logoutUserSession());
					}, (typeof timeInNumber === 'number' ? timeInNumber - 62 : 1) * 1000); // adding 62 seconds because for a new token we give 60 seconds
				}
			}
		},
		[isLoggedIn, sessionTimer]
	);

	useEffect(() => {
		checkLogin();
		sessionActivityEvents.forEach((singleEvent) => {
			window.addEventListener(singleEvent, () => checkSessionExpired(), true);
		});

		return () => {
			sessionActivityEvents.forEach((singleEvent) => {
				window.removeEventListener(singleEvent, () => checkSessionExpired(true), true);
			});
		};
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			checkSessionExpired();
		} else {
			checkSessionExpired(true);
		}
	}, [isLoggedIn]);
};
