import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons";
import React from "react"

interface PreviewModalProps {
    open: boolean;
    onClose?: () => void;
    getPlayerUrl: () => string;
    metadata?: any;
}

export const PreviewModal = ({open, onClose, getPlayerUrl, metadata}: PreviewModalProps) => {
    return <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        title='Click on backdrop to close'
        className={onClose ? 'preview-interact-modal' : undefined}
    >
        {onClose && <DialogTitle id="alert-dialog-title">
            <IconButton
                onClick={onClose}
                style={{ position: 'absolute', right: '0', top: '0' }}
                title='Close'
            >
                <Close fontSize="large" htmlColor="#fff" />
            </IconButton>
        </DialogTitle>}
        <DialogContent style={{ padding: '0', margin: '0', overflow: 'hidden' }}>
            <div
                style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#000',
                }}
            >
                <iframe
                    title={metadata?.title}
                    src={getPlayerUrl()}
                    width={'100%'}
                    height={'100%'}
                    style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, border: "none" }}
                    allowFullScreen={true}
                />
            </div>
        </DialogContent>
    </Dialog>
}