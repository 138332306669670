import { useEffect, useState } from 'react';
import { _loadPlayerConfiguration } from '../../../../../../services/playerConfigurationsService';
import { LocalStorageService } from '../../../../../../services/localStorageService';
import { _getAccountSettings } from '../../../../../../services/profileServices';

const usePlayerConfiguration = () => {
	const defaultAccountId = LocalStorageService.getDefaultAccountId();
	const [playerConfiguration, setPlayerConfiguration] = useState<any[]>([]);
	const [defaultPlayerConfiguration, setDefaultPlayerConfiguration] = useState<any[]>();

	const playerConfigurationToRender = playerConfiguration.map((config: any) => ({
		...config,
		isDefault: defaultPlayerConfiguration?.includes(config.id),
	}));

	useEffect(() => {
		const loadPlayerConfiguration = async () => {
			const playerConfiguration = await _loadPlayerConfiguration(defaultAccountId);

			if (!playerConfiguration || playerConfiguration.length === 0) {
				return;
			}

			const playerData = playerConfiguration
				.filter((config: any) => config.id !== 'Shopping')
				.map((config: any) => ({ id: config.id, name: config.name }));

			setPlayerConfiguration(playerData);
		};

		loadPlayerConfiguration();
	}, [defaultAccountId]);

	useEffect(() => {
		const loadPlayerDefaultConfigs = async () => {
			const result = await _getAccountSettings(defaultAccountId);
			if (!result || result.length === 0) {
				return;
			}

			const defaultPlayerConfigs = result['qbrickPlayer'].find((v: any) => v.version === '2')?.presets?.defaults;
			setDefaultPlayerConfiguration(defaultPlayerConfigs);
		};

		loadPlayerDefaultConfigs();
	}, [defaultAccountId]);

	return { playerConfigurationToRender };
};

export default usePlayerConfiguration;
