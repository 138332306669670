import { useEffect, useState } from 'react';
import {
	addNewAccountPortalThemeService,
	deleteAThemeService,
	fetchAccountPortalThemesService,
	fetchDefaultThemesService,
	toggleThemeFavoriteService,
	updateAccountPortalThemeService,
} from '../../../../../../services/portalApiService';
import { _getAccountSettings } from '../../../../../../services/profileServices';
import { cloneDeep, update } from 'lodash';
import { generateUUID } from '../../../../../../utils/commonUtil';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../../../../../actions/globalActions';
import { messageTypes } from '../../../../../../constants/mediaConstants';
import { LocalStorageService } from '../../../../../../services/localStorageService';

export type Theme = {
	themeId: string;
	accountId: string;
	name: string;
	favorite: boolean;
	styling: {
		[id: string]: any;
	};
	playback: {
		[id: string]: any;
	};
	isDefault?: boolean;
};

type IsLoadingType = {
	defaultThemes: boolean;
	accountThemes: boolean;
	defaultThemesId: boolean;
};

const useThemes = () => {
	const defaultAccountId = LocalStorageService.getDefaultAccountId();
	const dispatch = useDispatch();

	const [defaultThemes, setDefaultThemes] = useState<Theme[]>();
	const [accountThemes, setAccountThemes] = useState<Theme[]>();
	const [defaultThemesId, setDefaultThemesId] = useState<string[]>();
	const [themesToRender, setThemesToRender] = useState<Theme[]>([]);

	const [editingThemeId, setEditingThemeId] = useState<string | undefined>();
	const [userEditingThemeChanged, setUserEditingThemeChanged] = useState<Theme>();

	const [loadingData, setLoadingData] = useState<IsLoadingType>({
		defaultThemes: false,
		accountThemes: false,
		defaultThemesId: false,
	});

	// handle functional here
	const updatingEditingThemeId = (editingThemeId: string) => {
		setEditingThemeId(editingThemeId);
	};

	const updatingUserChange = (path: string, value: string) => {
		setUserEditingThemeChanged((prevState: Theme | undefined) => {
			if (!prevState) {
				return prevState;
			}

			const theme = cloneDeep(prevState);
			const updatedTheme = update(theme, path, () => value);
			return updatedTheme;
		});
	};

	const saveChangeTheme = async () => {
		if (!userEditingThemeChanged || !defaultAccountId) {
			return;
		}

		if (
			!accountThemes?.find((theme) => theme.themeId === userEditingThemeChanged.themeId) &&
			userEditingThemeChanged.isDefault
		) {
			saveAsNewTheme(true);
			return;
		}
		const result = await updateAccountPortalThemeService(userEditingThemeChanged, defaultAccountId);

		if (!result) {
			throw new Error('Failed to update theme');
		}

		dispatch(showMessage('Theme saved', messageTypes.success));
		setEditingThemeId(result.themeId);
		setAccountThemes((prevState) => [
			...(prevState?.filter((theme) => theme.themeId !== result.themeId) ?? []),
			result,
		]);
	};

	const saveAsNewTheme = async (
		isSaveNewDefaultTheme: boolean,
		themeName?: string,
		callback?: () => void,
		isShowAlert: boolean = true
	) => {
		if (!userEditingThemeChanged || !defaultAccountId) {
			return;
		}

		let newTheme = userEditingThemeChanged;

		if (!isSaveNewDefaultTheme) {
			newTheme = {
				...userEditingThemeChanged,
				themeId: generateUUID(),
				name: themeName ?? userEditingThemeChanged.name,
			};
		}

		const result = await addNewAccountPortalThemeService(newTheme as Theme, defaultAccountId);

		if (!result) {
			throw new Error('Failed to add theme');
		}

		isShowAlert && dispatch(showMessage('Theme saved', messageTypes.success));
		setEditingThemeId(result.themeId);
		setAccountThemes((prevState) => [
			...(prevState?.filter((theme) => theme.themeId !== result.themeId) ?? []),
			result,
		]);
		callback?.();
	};

	const resetDefaultTheme = (resetKey: string) => {
		const defaultTheme = defaultThemes?.find((theme) => theme.themeId === editingThemeId);

		if (!defaultTheme || !userEditingThemeChanged) {
			return;
		}

		const newTheme = {
			...cloneDeep(userEditingThemeChanged),
			[resetKey]: defaultTheme[resetKey as keyof typeof defaultTheme],
		};

		setUserEditingThemeChanged(newTheme);
	};

	const deleteATheme = async (themeId: string) => {
		if (!defaultAccountId) {
			return;
		}

		try {
			const result = await deleteAThemeService(themeId, defaultAccountId);

			if (result === '') {
				setEditingThemeId(undefined);
				setAccountThemes((prevState) => prevState?.filter((theme) => theme.themeId !== themeId) ?? []);
				dispatch(showMessage('Theme deleted', messageTypes.success));
			}
		} catch (error) {
			throw new Error('Failed to delete theme');
		}
	};

	const toggleThemeFavorite = async (themeId: string) => {
		const updatingTheme = themesToRender.find((theme) => {
			return theme.themeId === themeId;
		});

		if (!updatingTheme || !defaultAccountId) {
			return;
		}

		const newFavoriteValue = !updatingTheme.favorite;

		const updatedTheme = {
			...updatingTheme,
			favorite: newFavoriteValue,
		};

		const selectedThemeExistFromAccount = (accountThemes ?? []).find(
			({ themeId }) => themeId === updatedTheme?.themeId
		);

		if (!selectedThemeExistFromAccount) {
			await saveAsNewTheme(true, undefined, undefined, false);
		}

		let findCurrentThemeFavorite: Theme | undefined;
		if (newFavoriteValue) {
			findCurrentThemeFavorite = (accountThemes ?? []).find(({ favorite }) => favorite);
		}

		try {
			const result = await toggleThemeFavoriteService(updatedTheme, defaultAccountId);
			let resultRemoveFavorite;

			if (findCurrentThemeFavorite) {
				findCurrentThemeFavorite.favorite = false;
				resultRemoveFavorite = await toggleThemeFavoriteService(findCurrentThemeFavorite, defaultAccountId);
			}

			if (result === undefined || (findCurrentThemeFavorite && resultRemoveFavorite === undefined)) {
				throw new Error();
			}

			if (result === '') {
				setAccountThemes((prevState) => {
					const newThemes = (prevState ?? []).map((theme: Theme) => {
						if (theme.themeId === updatedTheme.themeId) {
							return updatedTheme;
						}

						if (theme.themeId === findCurrentThemeFavorite?.themeId) {
							return findCurrentThemeFavorite;
						}
						return theme;
					});

					return newThemes;
				});
				dispatch(showMessage('Theme favorite updated successfully', messageTypes.success));
			}
		} catch (error) {
			dispatch(
				showMessage(
					'Failed to update theme favorite. Please refresh the page and try again later.',
					messageTypes.error
				)
			);
		}
	};

	// Fetch default themes from HAL_9000
	useEffect(() => {
		const fetchDefaultThemes = async () => {
			const result = await fetchDefaultThemesService();

			if (!result) {
				setLoadingData((prevState) => ({ ...prevState, defaultThemes: false }));
				throw new Error('Failed to fetch default themes');
			}
			setLoadingData((prevState) => ({ ...prevState, defaultThemes: false }));
			setDefaultThemes(result);
		};
		setLoadingData((prevState) => ({ ...prevState, defaultThemes: true }));
		fetchDefaultThemes();
	}, []);

	// Fetch created themes from current account
	useEffect(() => {
		if (!defaultAccountId) {
			return;
		}

		const fetchCreatedThemes = async () => {
			const result = await fetchAccountPortalThemesService({ accountId: defaultAccountId });

			if (!result) {
				setLoadingData((prevState) => ({ ...prevState, accountThemes: false }));
				setAccountThemes([]);
				return;
			}

			setLoadingData((prevState) => ({ ...prevState, accountThemes: false }));
			setAccountThemes(result);
		};

		setLoadingData((prevState) => ({ ...prevState, accountThemes: true }));
		fetchCreatedThemes();
	}, [defaultAccountId]);

	//Fetch current account settings to see which default themes should be shown
	useEffect(() => {
		if (!defaultAccountId) {
			return;
		}

		const fetchAccountSettings = async () => {
			const accountSettings = await _getAccountSettings(defaultAccountId);

			if (!accountSettings || Object.keys(accountSettings).length === 0) {
				setLoadingData((prevState) => ({ ...prevState, defaultThemesId: false }));
				return;
			}

			if (accountSettings?.portalConfig?.defaultPresets?.length) {
				setDefaultThemesId(accountSettings?.portalConfig?.defaultPresets);
			} else {
				setDefaultThemesId([]);
			}

			setLoadingData((prevState) => ({ ...prevState, defaultThemesId: false }));
		};
		setLoadingData((prevState) => ({ ...prevState, defaultThemesId: true }));
		fetchAccountSettings();
	}, [defaultAccountId]);

	// Process to return valid themes to generate UI
	useEffect(() => {
		if (!defaultThemes || !accountThemes || !defaultThemesId) {
			return;
		}

		// Show nothing if no default themes
		if (defaultThemesId.length === 0) {
			setEditingThemeId(undefined);
			return;
		}

		const defaultThemesForCurrentAccount = defaultThemes.filter(({ themeId }) => defaultThemesId.includes(themeId));
		let themesToRender = accountThemes.map((theme) => {
			const { themeId } = theme;
			const isDefaultTheme = defaultThemesId.includes(themeId);
			return isDefaultTheme ? { ...theme, isDefault: true } : { ...theme, isDefault: false };
		});

		defaultThemesForCurrentAccount.forEach((defaultTheme) => {
			const defaultThemeItem = themesToRender.find((theme) => defaultTheme.themeId === theme.themeId);
			if (!defaultThemeItem) {
				themesToRender.push({ ...defaultTheme, isDefault: true });
			}
		});

		const initialSelectedThemeId =
			themesToRender.find((theme) => theme.favorite)?.themeId || themesToRender[0].themeId;

		//sort themes by name alphabetically
		themesToRender.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});

		setThemesToRender(themesToRender);
		!editingThemeId && setEditingThemeId(initialSelectedThemeId);
	}, [defaultThemes, accountThemes, defaultThemesId, editingThemeId]);

	// Update editing theme object when editingThemeId changes
	useEffect(() => {
		if (!editingThemeId) {
			setUserEditingThemeChanged(undefined);
			return;
		}

		const editingTheme = themesToRender.find((theme) => theme.themeId === editingThemeId);

		if (!editingTheme) {
			return;
		}

		setUserEditingThemeChanged(editingTheme);
	}, [editingThemeId, themesToRender]);

	return {
		themesToRender,
		editingThemeId,
		userEditingThemeChanged,
		loadingData,
		updatingEditingThemeId,
		updatingUserChange,
		saveChangeTheme,
		saveAsNewTheme,
		resetDefaultTheme,
		deleteATheme,
		toggleThemeFavorite,
	};
};

export default useThemes;
