import type { Theme } from '../screens/PublishScreen/InspectScreen/InspectSections/PortalThemes/hooks/useThemes';
import BackendClient from './backendClient';
import { readApiEndpointV1, writeApiEndpointV1 } from '../utils/config';
import { HAL_9000_ACCOUNT_ID } from '../constants/playerConfigurationsConstant';

export const fetchDefaultThemesService = async () => {
	return BackendClient.get(`${readApiEndpointV1}public/accounts/${HAL_9000_ACCOUNT_ID}/themes`);
};

type AccountPortalThemesServiceProps = {
	accountId: string;
};

export const fetchAccountPortalThemesService = async ({
	accountId,
}: AccountPortalThemesServiceProps): Promise<Theme[]> => {
	return BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/themes`);
};

export const updateAccountPortalThemeService = async (theme: Theme, accountId: string) => {
	const { themeId } = theme;
	return BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/themes/${themeId}`, theme, false, false);
};

export const addNewAccountPortalThemeService = async (theme: Theme, accountId: string) => {
	return BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/themes`,
		{ id: theme.themeId, name: theme.name, styling: theme.styling, playback: theme.playback },
		false
	);
};

export const deleteAThemeService = async (themeId: string, accountId: string) => {
	return BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/themes/${themeId}`);
};

export const toggleThemeFavoriteService = async (theme: Theme, accountId: string) => {
	const { themeId, favorite } = theme;

	return BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/themes/${themeId}/favorite`,
		favorite,
		false,
		{ 'Content-Type': 'application/json' }
	);
};
