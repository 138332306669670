import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControlLabel, IconButton, MenuItem, Select, Tab, Tabs, TextField } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import CloseIcon from '@material-ui/icons/Close';

import { setInspectMediaModified } from '../../../../actions/inspectScreenActions';
import { publishPortalDetailsAction, savePortalDetailsAction } from '../../../../actions/publishActions';
import portalThemePreview from '../../../../assets/images/portal-theme-preview.png';
import { fetchAccountPortalThemesService } from '../../../../services/portalApiService';
import { Theme } from '../../InspectScreen/InspectSections/PortalThemes/hooks/useThemes';
import { loadMediasPerNavigationWithTotalCount } from '../../../../services/mediaService';
import { mediaNames, sortTypes } from '../../../../constants/mediaConstants';
import { MediaDetailUtils } from '../utils';
import { portalsUrl } from '../../../../utils/config';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { LocalStorageService } from '../../../../services/localStorageService';
import { _getAccountSettings } from '../../../../services/profileServices';

type PortalContentProps = {
	baseClassName: string;
	portalDetail: any;
	miniMediaLibraryRef: any;
	handleOnDataChanges: () => void;
	reset: () => void;
};

type HeroMedia = {
	id: string;
	type: string;
	title: string;
	description?: string;
};

const PortalContent = forwardRef(
	({ baseClassName, portalDetail, miniMediaLibraryRef, handleOnDataChanges, reset }: PortalContentProps, ref) => {
		const { t } = useTranslation();
		const dispatch = useDispatch<any>();
		const { defaultAccountId } = useSelector((state: any) => state.session);

		const [selectedTab, setSelectedTab] = useState<string>('detail');

		const [themes, setThemes] = useState<Theme[]>([]);
		const [playlists, setPlaylists] = useState<any[]>([]);

		const [pageTitle, setPageTitle] = useState<string>('');
		const [selectedThemesId, setSelectedThemesId] = useState<string>('');
		const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('');
		const [selectedHeroMedia, setSelectedHeroMedia] = useState<HeroMedia | null>(null);

		useImperativeHandle(ref, () => {
			return { onSaveChanges, onPublishPortal, onSelectedHeroMedia };
		});

		const onSaveChanges = async (callback: any = null) => {
			if (!portalDetail) {
				return;
			}

			const newData = {
				content: {
					...portalDetail.content,
					name: pageTitle,
					playlistId: selectedPlaylistId,
					heroVideo: selectedHeroMedia || undefined,
				},
				themeId: selectedThemesId,
			};

			try {
				await dispatch(savePortalDetailsAction(defaultAccountId, portalDetail.id, newData));
				const previewUrl = `${portalsUrl}${
					portalDetail.id
				}?pu=${LocalStorageService.getAccessToken()}&aid=${defaultAccountId}`;

				callback?.(previewUrl);
			} catch (e) {
				console.info('Error saving portal', e);
			}
		};

		const onPublishPortal = async (callback: any) => {
			if (!portalDetail) {
				return;
			}

			try {
				await onSaveChanges();
				await dispatch(publishPortalDetailsAction(defaultAccountId, portalDetail.id));
				const previewUrl = `${portalsUrl}${portalDetail.code}`;
				callback?.(previewUrl);
			} catch (e) {
				console.info('Error publishing portal', e);
			}
		};

		const handleUpdatingData = useCallback(() => {
			handleOnDataChanges?.();
			dispatch(setInspectMediaModified(true));
		}, [dispatch, handleOnDataChanges]);

		const onSelectedHeroMedia = (media: any) => {
			if (!media || !media?.[0]) {
				return;
			}

			const selectedMedia = media[0];

			const { type, title, id, description } = selectedMedia;
			setSelectedHeroMedia({ type, title, id, description });
			handleUpdatingData();
		};

		const handleClearSelectedHeroMedia = () => {
			setSelectedHeroMedia(null);
			handleUpdatingData();
		};

		// Fetch account themes
		useEffect(() => {
			if (!defaultAccountId) {
				return;
			}

			const fetchCreatedThemes = async () => {
				const result = await fetchAccountPortalThemesService({ accountId: defaultAccountId });

				if (!result) {
					setThemes([]);
					return;
				}

				setThemes(result.sort((a, b) => a.name.localeCompare(b.name)));
			};

			fetchCreatedThemes();
		}, [defaultAccountId]);

		// Fetch account playlists
		useEffect(() => {
			if (!defaultAccountId) {
				return;
			}

			const fetchCreatedPlaylists = async () => {
				const result = await loadMediasPerNavigationWithTotalCount({
					accountId: defaultAccountId,
					mediaPerPage: 200,
					offset: 0,
					mediaName: `${mediaNames.playlists}?`,
					sortType: sortTypes.newest,
					query: '',
					signal: undefined,
				});

				if (!result) {
					setPlaylists([]);
					return;
				}

				setPlaylists(result.data);
			};

			fetchCreatedPlaylists();
		}, [defaultAccountId]);

		//Reinitialize the page title when portalDetail changes
		useEffect(() => {
			const {
				content: { name, playlistId, heroVideo },
				themeId,
			} = portalDetail;

			setPageTitle(name);
			setSelectedPlaylistId(playlistId);
			setSelectedThemesId(themeId);
			setSelectedHeroMedia(heroVideo);
			reset?.();
		}, [portalDetail]);

		const portalSharingUrl = `${portalsUrl}${portalDetail.code}`;

		return (
			<>
				<div className={`${baseClassName}__player-container`}>
					<img
						src={portalThemePreview}
						alt="Portal theme thumbnail"
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
					/>
				</div>

				<div className={`${baseClassName}__media-content-container`}>
					<Tabs
						value={selectedTab}
						onChange={(_e, value) => setSelectedTab(value)}
						orientation="vertical"
						className={`${baseClassName}__tab-bar`}
						TabIndicatorProps={{
							style: {
								color: '#ffffff',
								backgroundColor: 'transparent',
							},
						}}
					>
						{MediaDetailUtils.MenuItems.map((item) => (
							<Tab
								key={item.value}
								value={item.value}
								icon={selectedTab === item.value ? item.activeIcon : item.icon}
								style={
									selectedTab === item.value
										? { backgroundColor: '#126CFC1A', opacity: '1' }
										: { opacity: '1' }
								}
								label={item.label}
							/>
						))}
					</Tabs>

					<div className={`${baseClassName}__media-content-container`}>
						<div className="inspect-media__detail-container">
							<div className={'inspect-media__general-info-container'}>
								{selectedTab === 'detail' && (
									<Box>
										<FormControlLabel
											className={'inspect-media__input--full'}
											style={{ display: 'flex' }}
											control={
												<Select
													value={selectedThemesId}
													variant="outlined"
													fullWidth
													onChange={(
														event: React.ChangeEvent<{
															name?: string;
															value: unknown;
														}>
													) => {
														const value = event.target.value as string;
														setSelectedThemesId(value);
														handleUpdatingData();
													}}
												>
													{themes.length === 0 ? (
														<MenuItem key={0} value={0}>
															No themes available
														</MenuItem>
													) : (
														themes.map(({ name, themeId }: Theme) => {
															return (
																<MenuItem key={themeId} value={themeId}>
																	{name.length > 60
																		? `${name.substring(0, 60)}`
																		: name}
																</MenuItem>
															);
														})
													)}
												</Select>
											}
											label={
												<span className={'inspect-media__input-label'}>
													{t('INSPECT_MEDIA_MINI_PORTAL_CONFIG_CHOOSE_THEME')}
												</span>
											}
											labelPlacement={'top'}
										/>
										<FormControlLabel
											className={'inspect-media__input--full'}
											style={{ display: 'flex' }}
											control={
												<TextField
													fullWidth
													variant="outlined"
													value={pageTitle}
													placeholder={t(
														'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_PLAYLIST_ENTER_TITLE'
													)}
												/>
											}
											label={
												<span className={'inspect-media__input-label'}>
													{t('INSPECT_MEDIA_MINI_PORTAL_CONFIG_PAGE_TITLE')}
												</span>
											}
											onChange={(e: any) => {
												handleUpdatingData();
												setPageTitle(e.target.value);
											}}
											labelPlacement={'top'}
										/>
										<FormControlLabel
											className={'inspect-media__input--full'}
											style={{ display: 'flex', cursor: 'pointer' }}
											onClick={() => {
												miniMediaLibraryRef.current?.showMediaLibrary(true);
											}}
											control={
												<TextField
													fullWidth
													variant="outlined"
													value={selectedHeroMedia?.title ?? 'Choose video'}
													placeholder={t(
														'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_PLAYLIST_ENTER_TITLE'
													)}
													inputProps={{
														style: {
															cursor: 'pointer',
														},
													}}
													InputProps={{
														readOnly: true,
														startAdornment: (
															<SubscriptionsIcon style={{ cursor: 'pointer' }} />
														),
														endAdornment: selectedHeroMedia ? (
															<>
																<IconButton
																	size="small"
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		handleClearSelectedHeroMedia();
																	}}
																>
																	<CloseIcon />
																</IconButton>
															</>
														) : (
															<Button
																size="small"
																color="primary"
																variant="contained"
																style={{
																	whiteSpace: 'nowrap',
																	padding: '2px 20px',
																	fontSize: '12px',
																}}
																onClick={(e) => {
																	e.preventDefault();
																	e.stopPropagation();
																	miniMediaLibraryRef.current?.showMediaLibrary(true);
																}}
															>
																Choose video
															</Button>
														),
													}}
												/>
											}
											label={
												<span className={'inspect-media__input-label'}>
													{t('Select hero video')}
												</span>
											}
											labelPlacement={'top'}
										/>
										<FormControlLabel
											className={'inspect-media__input--full'}
											style={{ display: 'flex' }}
											control={
												<Select
													value={selectedPlaylistId}
													variant="outlined"
													fullWidth
													onChange={(
														event: React.ChangeEvent<{
															name?: string;
															value: unknown;
														}>
													) => {
														const value = event.target.value as string;
														setSelectedPlaylistId(value);
														handleUpdatingData();
													}}
												>
													{playlists.map(({ id, metadata }: any) => {
														const name = metadata?.title ?? 'No title';
														return (
															<MenuItem key={id} value={id}>
																{name.length > 60
																	? `${name.substring(0, 60)}...`
																	: name}
															</MenuItem>
														);
													})}
												</Select>
											}
											label={
												<span className={'inspect-media__input-label'}>
													{t('INSPECT_MEDIA_MINI_PORTAL_CONFIG_ADD_PLAYLIST')}
												</span>
											}
											labelPlacement={'top'}
										/>
									</Box>
								)}

								{selectedTab === 'share' && (
									<Box>
										<FormControlLabel
											className={'inspect-media__input--full'}
											style={{ display: 'flex' }}
											control={
												<Box
													display="flex"
													flexDirection="row"
													style={{ width: '100%' }}
													justifyContent="center"
													alignItems="center"
												>
													<Box style={{ flex: 1, margin: 0 }}>
														<TextField
															fullWidth
															variant="outlined"
															value={portalSharingUrl}
															placeholder={t(
																'MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_PLAYLIST_ENTER_TITLE'
															)}
														/>
													</Box>

													<CopyToClipboard text={portalSharingUrl}>
														<IconButton>
															<FileCopyIcon />
														</IconButton>
													</CopyToClipboard>
												</Box>
											}
											label={
												<span className={'inspect-media__input-label'}>
													{t('INSPECT_MEDIA_MINI_PORTAL_CONFIG_SHARE_LINK')}
												</span>
											}
											labelPlacement={'top'}
										/>
									</Box>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
);

export default PortalContent;
