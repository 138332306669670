export const publicConfigIds = {
	Enterprise: 'Enterprise',
	Interactive: 'Interactive',
};

export const HAL_9000_ACCOUNT_ID = 'Accpy7KNHj1IUilFL5BMuNL0Q';

export const defaultSettingTypes = {
	enterprise: {
		name: 'Enterprise player',
		note: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_PRESET_REGULAR_NOTE',
	},
	interactive: {
		name: 'Interactive player',
		note: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_PRESET_INTERACTIVE_NOTE',
	},
	shopping: {
		name: 'Shopping player',
		note: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_PRESET_SHOPPING_NOTE',
	},
};

export const advancedSettingTypes = {
	playbackOptions: 'playbackOptions',
	playCover: 'playCover',
	liveCountdown: 'liveCountdown',
	topControls: 'topControls',
	bottomControls: 'bottomControls',
	engagementFeatures: 'engagementFeatures',
	accessibilitySettings: 'accessibilitySettings',
	subtitleStyling: 'subtitleStyling',
	analyticsSettings: 'analyticsSettings',
	playerStyling: 'playerStyling',
	additionalSettings: 'additionalSettings',
};

export const playbackOptionsKeys = [
	'maintainProportions',
	'autostart',
	'muteAtStart',
	'showUnmuteBtn',
	'replay',
	'subtitlesAutoOn',
	'setDefaultVolume',
];

export const advancedSettingTypesOrder = {
	playbackOptions: 1,
	playCover: 2,
	liveCountdown: 3,
	topControls: 4,
	bottomControls: 5,
	engagementFeatures: 6,
	accessibilitySettings: 7,
	analyticsSettings: 8,
	playerStyling: 9,
	additionalSettings: 10,
};

export const playCoverOptionsKeys = ['playButtonSize', 'playButtonPosition', 'customPlayButtonIcon', 'showVideoLength'];
export const liveCountdownOptionsKeys = [
	'coverStyle',
	'customBackground',
	'titlePosition',
	'eventStartPosition',
	'countdownPosition',
];

export const topControlsKeys = [
	'showTitle',
	'showExcludingTitleOnDomains',
	'showTitleDuringPlayback',
	'showAccountName',
	'showLogotype',
	'showExcludingAccountNameOnDomains',
	'showAccountNameDuringPlayback',
	'showSocialSharing',
	'showDownloadButton',
	'showScreenGrab',
	'showOpenInNewWindow',
	'showHoverShadow',
	'alwaysVisible',
	'showSolidBackgroundTopControl',
];

export const controlPositions = [
	{ label: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS', value: 'bottom' },
	{ label: 'SETTINGS_PRESET_CONFIG_CENTER_CONTROLS', value: 'center' },
];
export const bottomControlsKeys = [
	'showPlaybackControls',
	'showPlayPauseButton',
	'playPausePosition',
	'showTimeline',
	'showTime',
	'showRewindForwardButtons',
	'showAudioControls',
	'showCastDevices',
	'showLanguageSettingsButton',
	'showSubtitlesSettings',
	'showAudioTracksSettings',
	'showSettingsButton',
	'showVideoQualitySettings',
	'showPictureInPicture',
	'showFullscreen',
	"showFullscreenUsingSoftbox",
	'showHoverShadow',
	'alwaysVisible',
	'showSolidBackgroundBottomControl',
];

export const engagementFeaturesKeys = ['showShoppingCart', 'showChat', 'openChatOnPlay', 'showLiveLikes'];

export const accessibilitySettingsKeys = ['language'];
export const subtitleStylingKeys = ['position'];

export const solidBackgroundStylingKeys = {
	showSolidBackgroundTopControl: 'solidBackgroundTopColor',
	showSolidBackgroundBottomControl: 'solidBackgroundBottomColor',
};

export const analyticsSettingsKeys = {
	gaMeasurementId: 'gaMeasurementId',
};

export const playerStylingKeys = ['primaryColor', 'playerFont', 'useCustomFont'];

export const additionalSettingsKeys = ['noCookies', 'noChatCookies', 'noSchemaData'];

export const advancedSettingLabels = {
	uniqueFrame: 'SETTINGS_PRESET_CONFIG_LABE_UNIQUE_FRAME',

	playbackOptions: 'SETTINGS_PRESET_CONFIG_PLAYBACK_OPTIONS',
	replay: 'SETTINGS_PRESET_CONFIG_LABEL_REPLAY',
	muteAtStart: 'SETTINGS_PRESET_CONFIG_LABEL_MUTE_ON_PLAY',
	showUnmuteBtn: 'SETTINGS_PRESET_CONFIG_LABEL_SHOW_UNMUTE_BTN',
	softFullscreen: 'SETTINGS_PRESET_CONFIG_LABEL_SOFT_FULL_SCREEN',
	autostart: 'SETTINGS_PRESET_CONFIG_LABEL_AUTO_PLAY',
	maintainProportions: 'SETTINGS_PRESET_CONFIG_MAINTAIN_VIDEO_PROPORTIONS',
	subtitlesAutoOn: 'SETTINGS_PRESET_CONFIG_SUBTITLE_AUTO_ON',
	setDefaultVolume: 'SETTINGS_PRESET_CONFIG_DEFAULT_VOLUME',

	playCover: 'SETTINGS_PRESET_CONFIG_PLAY_COVER',
	playButtonSize: 'SETTINGS_PRESET_CONFIG_PLAY_BUTTON_SIZE',
	playButtonPosition: 'SETTINGS_PRESET_CONFIG_PLAY_BUTTON_POSITION',
	customPlayButtonIcon: 'SETTINGS_PRESET_CONFIG_CUSTOM_PLAY_BUTTON_ICON',
	customPlayButtonUrlPlaceholder: 'SETTINGS_PRESET_CONFIG_CUSTOM_PLAY_BUTTON_ICON_URL_PLACEHOLDER',
	showVideoLength: 'SETTINGS_PRESET_CONFIG_PLAY_BUTTON_SHOW_VIDEO_LENGTH',

	liveCountdown: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN',
	coverStyle: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN_COVER_STYLE',
	customBackground: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN_BG_URL',
	titlePosition: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN_TITLE_POSITION',
	eventStartPosition: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN_EVENT_START_POSITION',
	countdownPosition: 'SETTINGS_PRESET_CONFIG_LIVE_COUNTDOWN_COUNTDOWN_POSITION',

	topControls: 'SETTINGS_PRESET_CONFIG_TOP_CONTROLS',
	showTitle: 'SETTINGS_PRESET_CONFIG_SHOW_TITLE',
	showExcludingTitleOnDomains: 'SETTINGS_PRESET_CONFIG_SHOW_TITLE_EXCLUDING_DOMAIN',
	showExcludingAccountNameOnDomains: 'SETTINGS_PRESET_CONFIG_SHOW_TITLE_EXCLUDING_DOMAIN',
	showExcludingAccountNameOnDomainsPlaceholder: 'SETTINGS_PRESET_CONFIG_SHOW_TITLE_EXCLUDING_DOMAIN_PLACEHOLDER',
	showTitleDuringPlayback: 'SETTINGS_PRESET_CONFIG_SHOW_TITLE_DURING_PLAYBACK',
	showAccountName: 'SETTINGS_PRESET_CONFIG_SHOW_ACC_NAME',
	showAccountNamePlaceholder: 'SETTINGS_PRESET_CONFIG_SHOW_ACC_NAME_PLACEHOLDER',
	showAccountNameDuringPlayback: 'SETTINGS_PRESET_CONFIG_SHOW_ACC_NAME_DURING_PLAYBACK',
	showLogotype: 'SETTINGS_PRESET_CONFIG_LOGOTYPE_URL',
	logotypeUrlPlaceholder: 'SETTINGS_PRESET_CONFIG_LOGOTYPE_URL_PLACEHOLDER',
	showSocialSharing: 'SETTINGS_PRESET_CONFIG_SHOW_SOCIAL_SHARING',
	shareSettings: 'SETTINGS_PRESET_CONFIG_SOCIAL_SHARING_SETTINGS',
	customUrl: 'SETTINGS_PRESET_CONFIG_SOCIAL_SHARING_CUSTOM_URL',
	showDownloadButton: 'SETTINGS_PRESET_CONFIG_LABE_DOWNLOAD_BUTTON',
	showScreenGrab: 'SETTINGS_PRESET_CONFIG_LABEL_SHOW_SCREEN_GRAB',
	showOpenInNewWindow: 'SETTINGS_PRESET_CONFIG_LABE_PLAYER_OPEN_IN_NEW_WINDOW',
	showHoverShadow: 'SETTINGS_PRESET_CONFIG_HOVER_SHADOW',
	alwaysVisible: 'SETTINGS_PRESET_CONFIG_ALWAYS_VISIBLE',
	showSolidBackgroundTopControl: 'SETTINGS_PRESET_CONFIG_SHOW_SOLID_BACKGROUND',

	bottomControls: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS',
	showPlaybackControls: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS_PLAYBACK',
	showPlayPauseButton: 'SETTINGS_PRESET_CONFIG_PLAY_PAUSE_BUTTON',
	showTime: 'SETTINGS_PRESET_CONFIG_PLAY_SHOW_TIME',
	showRewindForwardButtons: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS_REWIND',
	showSettingsButton: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS_SETTINGS',
	showVideoQualitySettings: 'SETTINGS_PRESET_CONFIG_VIDEO_QUALITY',
	showLanguageSettingsButton: 'SETTINGS_PRESET_CONFIG_LANGUAGE_SETTINGS',
	showSubtitlesSettings: 'SETTINGS_PRESET_CONFIG_LANGUAGE_SETTINGS_SUBTITLES',
	showAudioTracksSettings: 'SETTINGS_PRESET_CONFIG_LANGUAGE_SETTINGS_AUDIO_TRACKS',
	showTimeline: 'SETTINGS_PRESET_CONFIG_BOTTOM_CONTROLS_TIMELINE',
	showPictureInPicture: 'SETTINGS_PRESET_CONFIG_PICTURE_IN_PICTURE',
	showAudioControls: 'SETTINGS_PRESET_CONFIG_AUDIO_CONTROLS',
	showFullscreen: 'SETTINGS_PRESET_CONFIG_FULL_SCREEN',
	showFullscreenUsingSoftbox: 'SETTINGS_PRESET_CONFIG_FULL_SCREEN_SOFTBOX',
	showCastDevices: 'SETTINGS_PRESET_CONFIG_SHOW_CAST_DEVICES',
	showSolidBackgroundBottomControl: 'SETTINGS_PRESET_CONFIG_SHOW_SOLID_BACKGROUND',

	engagementFeatures: 'SETTINGS_ENGAGEMENT_FEATURES',
	showShoppingCart: 'SETTINGS_PRESET_CONFIG_SHOPPING_CART',
	showChat: 'COMMON_CHAT',
	showLiveLikes: 'SETTINGS_PRESET_CONFIG_LIVE_LIKES',
	openChatOnPlay: 'SETTINGS_PRESET_CONFIG_OPEN_CHAT_ON_PLAY',

	accessibilitySettings: 'SETTINGS_PRESET_CONFIG_A11Y_SETTINGS',
	subtitleStyling: 'SETTINGS_PRESET_CONFIG_SUBTITLE_STYLING',
	subtitlePosition: 'SETTINGS_PRESET_CONFIG_SUBTITLE_POSITION',

	analyticsSettings: 'SETTINGS_PRESET_CONFIG_ANALYTICS_SETTINGS',
	gaMeasurementId: 'SETTINGS_PRESET_CONFIG_GA_MEASUREMENT_ID',

	language: 'SETTINGS_PRESET_CONFIG_A11Y_LANGUAGE',

	playerStyling: 'SETTINGS_PRESET_CONFIG_PLAYER_STYLING',
	menuBackgroundColor: 'SETTINGS_PRESET_CONFIG_MENU_BG',
	hoverColor: 'SETTINGS_PRESET_CONFIG_HOVER_COLOR',
	primaryColor: 'SETTINGS_PRESET_CONFIG_PRIMARY_COLOR',
	playerFont: 'SETTINGS_PRESET_CONFIG_PLAYER_FONT',
	playerCustomFontUrl: 'SETTINGS_PRESET_CONFIG_PLAYER_CUSTOM_FONT_URL',
	playerCustomFontName: 'SETTINGS_PRESET_CONFIG_PLAYER_CUSTOM_FONT_NAME',
	useCustomFont: 'SETTINGS_PRESET_CONFIG_USE_CUSTOM_FONT',
	controlBackgroundColor: 'SETTINGS_PRESET_CONFIG_CONTROL_BG',
	textColor: 'SETTINGS_PRESET_CONFIG_ICON_AND_TEXT',

	additionalSettings: 'SETTINGS_PRESET_CONFIG_ADDITIONAL_SETTINGS',
	noCookies: 'SETTINGS_PRESET_CONFIG_NO_ANALYTICS_COOKIES',
	noChatCookies: 'SETTINGS_PRESET_CONFIG_NO_CHAT_DATA',
	noSchemaData: 'SETTINGS_PRESET_CONFIG_NO_SCHEMA',
};

export const subDataKeys = {
	showPlayPauseButton: 'showPlaybackControls',
	showTimeline: 'showPlaybackControls',
	showTime: 'showTime',
	showRewindForwardButtons: 'showPlaybackControls',
	showAudioControls: 'showPlaybackControls',
	showSubtitlesSettings: 'showLanguageSettingsButton',
	showAudioTracksSettings: 'showLanguageSettingsButton',
	showVideoQualitySettings: 'showSettingsButton',
	openChatOnPlay: 'openChatOnPlay',
	showExcludingTitleOnDomains: 'showExcludingTitleOnDomains',
	showTitleDuringPlayback: 'showTitleDuringPlayback',
	showExcludingAccountNameOnDomains: 'showExcludingAccountNameOnDomains',
	showAccountNameDuringPlayback: 'showAccountNameDuringPlayback',
	showUnmuteBtn: 'showUnmuteBtn',
	showFullscreenUsingSoftbox: "showFullscreenUsingSoftbox"
};

export const mobileOnlyDataKeys = {
	showFullscreenUsingSoftbox: "showFullscreenUsingSoftbox",
	maintainProportions: 'maintainProportions',
};

export const extendedDataKeys = {
	playPausePosition: "playPausePosition"
}

export const trueByDefaultDataKeys = {
	showFullscreenUsingSoftbox: "showFullscreenUsingSoftbox",
	showUnmuteBtn: "showUnmuteBtn"
}

export const headerWithCheckbox = {
	topControls: 'topControls',
	bottomControls: 'bottomControls',
	engagementFeatures: 'engagementFeatures',
};

export const opacityKeys = {
	menuBackgroundColor: 'menuBackgroundOpacity',
	controlBackgroundColor: 'controlBackgroundOpacity',
	textColor: 'textOpacity',
	hoverColor: 'hoverOpacity',
	primaryColor: 'primaryOpacity',
	showSolidBackgroundTopControl: 'solidBackgroundTopControlOpacity',
	showSolidBackgroundBottomControl: 'solidBackgroundBottomControlOpacity',
};

export const advancedSettingsHelperTexts = {
	noCookies: 'SETTINGS_PRESET_CONFIG_NO_COOKIES_HELPER_TEXT',
	noChatCookies: 'SETTINGS_PRESET_CONFIG_NO_CHAT_DATA_HELPER_TEXT',
	showFullscreenUsingSoftbox: "SETTINGS_PRESET_CONFIG_FULL_SCREEN_SOFTBOX_TOOLTIP",
};

export const fonts = ['Arial', 'Poppins', 'Roboto', 'Open Sans'];

export const fontWeights = [
	'100',
	'200',
	'300',
	'400',
	'500',
	'600',
	'700',
	'800',
	'900',
	'normal',
	'bold',
	'lighter',
	'bolder',
];

export const NEW_EMBED_VERSION_2 = {
	qbrickPlayer: [
		{
			version: '2',
			enabled: true,
			presets: {
				defaults: '[Enterprise,Shopping,Interactive]',
			},
		},
	],
};

export const PLAY_COVER_CONSTANTS = {
	playButtonSizeWidth: 120,
	playButtonPositionX: 50,
	playButtonPositionY: 50,
	customPlayButtonIcon: false,
	customPlayButtonUrl: '',
};

export const LIVE_COUNTDOWN_CONSTANTS_DESKTOP = {
	coverStyle: 'default',
	customBackground: {
		enabled: false,
		url: '',
	},
	titlePosition: {
		enabled: false,
		x: 10,
		y: 15,
		fontsize: 'large',
	},
	eventStartPosition: {
		enabled: false,
		x: 10,
		y: 10,
		fontsize: 'small',
	},
	countdownPosition: {
		enabled: false,
		x: 10,
		y: 45,
		fontsize: 'small',
	},
};

export const LIVE_COUNTDOWN_CONSTANTS_MOBILE = {
	coverStyle: 'default',
	customBackground: {
		enabled: false,
		url: '',
	},
	titlePosition: {
		enabled: false,
		x: 10,
		y: 15,
		fontsize: 'large',
	},
	eventStartPosition: {
		enabled: false,
		x: 10,
		y: 10,
		fontsize: 'small',
	},
	countdownPosition: {
		enabled: false,
		x: 10,
		y: 45,
		fontsize: 'small',
	},
};
export const COUNTDOWN_FONT_SIZES = [
	{
		value: 'large',
		label: 'Large',
	},
	{
		value: 'medium',
		label: 'Medium',
	},
	{
		value: 'small',
		label: 'Small',
	},
];

export const SHARE_SETTINGS = {
	urlOrigin: 'directLink',
	customUrl: '',
};

export const SHARE_ORIGIN_ITEMS = [
	{
		value: 'directLink',
		label: 'Direct link',
	},
	{
		value: 'siteEmbed',
		label: 'Site embed',
	},
	{
		value: 'custom',
		label: 'Custom',
	},
];
