import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import { getPermission } from '../../../../actions/permissionActions';
import { featurePermissionsList } from '../../../../constants/featurePermissionsConstant';

class UserPermissionsList extends Component {
	getFeatureName = (featureId) => {
		const feature = (this.props.featurePermissions ?? []).find((f) => f.featureId === featureId);

		if (!feature) {
			return undefined;
		}

		if (feature.featureId === featurePermissionsList.scenarios.featureId) {
			return featurePermissionsList.scenarios.name;
		}

		if (feature.featureId === featurePermissionsList.interact.featureId) {
			return featurePermissionsList.interact.name;
		}

		if (feature.name === 'InteractiveEditor') {
			return this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_INTERACTIVE_MANAGER');
		}

		return feature.name;
	};

	render() {
		const { userPermissions } = this.props;
		const list = userPermissions && Array.isArray(userPermissions) ? _.sortBy(userPermissions, ['name']) : [];
		return (
			<>
				{(list ?? []).map((permission) => this.getFeatureName(permission.featureId)).filter(Boolean).sort().join(', ')}
			</>
		);
	}
}

UserPermissionsList.propTypes = {
	userId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(UserPermissionsList);
