import axios from 'axios';
import { store } from '../index';
import { LocalStorageService } from './localStorageService';
import {
	setUploadPercentage,
	removeFromUploadPercentage,
	addToIsPreparingForUpload,
	removeFromPreparingForUpload,
	addToIsEncoding,
	setIsAudioUploadFinished,
} from '../actions/publishActions';
import { showAlert } from '../actions/globalActions';

class BackendClient {
	createInstance = (excludeAccessToken) => {
		const instance = axios.create({
			timeout: 2000,
		});

		if (excludeAccessToken) {
			delete instance.defaults.headers.authorization;
		}
		return instance;
	};

	getRequestHeader = (excludeAccessToken) => {
		let header = {
			Accept: '*/*',
			'Access-Control-Allow-Origin': '*',
			Authorization: excludeAccessToken ? '' : `Bearer ${LocalStorageService.getAccessToken()}`,
		};
		if (excludeAccessToken) {
			delete header.authorization;
		}
		return header;
	};

	enableRequestInterceptors = (excludeAccessToken) => {
		axios.interceptors.request.use(
			(request) => {
				request.headers = { ...request.headers, ...this.getRequestHeader(excludeAccessToken) };
				return request;
			},
			(error) => {
				Promise.reject(error);
			}
		);
	};

	getJson = (path) => {
		this.enableRequestInterceptors();
		return fetch(path)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				// Work with JSON data here
				return data;
			})
			.catch((err) => {
				// Do something for an error here
				console.error(err);
			});
	};

	get = (path, excludeAccessToken = false) => {
		let instance = excludeAccessToken ? this.createInstance(excludeAccessToken) : axios;
		this.enableRequestInterceptors();

		return instance
			.get(path)
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	delete = (path) => {
		this.enableRequestInterceptors();
		return axios
			.delete(path)
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	put = (path, data, stringify = true, dataAsParam = true) => {
		this.enableRequestInterceptors();
		return axios
			.put(path, dataAsParam ? { data: stringify ? JSON.stringify(data) : data } : data, {
				headers: this.getRequestHeader(false),
			})
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	postTrascription = (path, body) => {
		this.enableRequestInterceptors();
		return axios
			.post(path, body, {
				headers: {
					Accept: '*/*',
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
				},
			})
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	putTrascription = (path, body) => {
		this.enableRequestInterceptors();
		return axios
			.put(path, body, {
				headers: {
					Accept: '*/*',
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
				},
			})
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	post = (path, body, isUploadRequest = false, otherHeaders) => {
		this.enableRequestInterceptors();

		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);
			return axios
				.post(path, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: function (progressEvent) {
						var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						store.dispatch(setUploadPercentage(body.file.name, percentCompleted, body.file.type));

						if (percentCompleted >= 100) {
							store.dispatch(removeFromUploadPercentage(body.file.name));
							store.dispatch(addToIsEncoding(body.file.name));
						}
					},
				})
				.then((data) => data)
				.catch((error) => {
					console.error(error);
				});
		} else {
			return axios
				.post(path, body, {
					headers: otherHeaders ?? {},
				})
				.then((response) => response.data)
				.catch((error) => {
					console.error(error);
				});
		}
	};

	postUploadVideoFlow = (path, body, isUploadRequest = false, fileName) => {
		this.enableRequestInterceptors();
		433;
		store.dispatch(addToIsPreparingForUpload(fileName));
		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);
			return axios
				.post(path, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((data) => {
					if (!data) {
						store.dispatch(removeFromPreparingForUpload(fileName));
					}
					return data;
				})
				.catch((error) => {
					store.dispatch(showAlert(''));
					store.dispatch(showAlert('Failed to create job. ' + error.message));
					store.dispatch(removeFromPreparingForUpload(fileName));
					return error;
				});
		} else {
			return axios
				.post(path, body)
				.then((response) => {
					if (!response.data) {
						store.dispatch(removeFromPreparingForUpload(fileName));
					}
					return response.data;
				})
				.catch((error) => {
					store.dispatch(showAlert(''));
					store.dispatch(showAlert('Failed to create job. ' + error.message));
					store.dispatch(removeFromPreparingForUpload(fileName));
					return error;
				});
		}
	};

	postUploadVideo = (path, body, isUploadRequest = false, xCallback) => {
		const instance = axios.create({
			//baseURL: baseUrl
		});

		let header = {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
			'Access-Control-Allow-Origin': '*',
			Host: 'upload.qbrick.com',
			Authorization: `Bearer ${LocalStorageService.getUploadAccessToken()}`,
			...(xCallback ?? {}),
		};

		instance.interceptors.request.use(
			(request) => {
				request.headers = header;
				return request;
			},
			(error) => {
				Promise.reject(error);
			}
		);

		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);

			return instance
				.post(path, formData, {
					//return axios({methos: 'post', url:path, data: formData,
					onUploadProgress: function (progressEvent) {
						var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						store.dispatch(setUploadPercentage(body.file.name, percentCompleted, body.file.type));

						if (percentCompleted >= 100) {
							store.dispatch(removeFromUploadPercentage(body.file.name));
							store.dispatch(addToIsEncoding(body.file.name));
						}
					},
				})
				.then((data) => data)
				.catch((error) => {
					console.error(error);
				});
		} else {
			return axios
				.post(path, body)
				.then((response) => response.data)
				.catch((error) => {
					console.error(error);
				});
		}
	};

	postUploadAudio = (path, body, isUploadRequest = false, xCallback) => {
		const instance = axios.create({
			//baseURL: baseUrl
		});

		let header = {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
			'Access-Control-Allow-Origin': '*',
			Host: 'upload.qbrick.com',
			Authorization: `Bearer ${LocalStorageService.getUploadAccessToken()}`,
			...(xCallback ?? {}),
		};

		instance.interceptors.request.use(
			(request) => {
				request.headers = header;
				return request;
			},
			(error) => {
				Promise.reject(error);
			}
		);

		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);

			return instance
				.post(path, formData, {
					onUploadProgress: function (progressEvent) {
						if (progressEvent.loaded === progressEvent.total) {
							store.dispatch(setIsAudioUploadFinished(Math.random()));
						}
					},
				})
				.then((data) => data)
				.catch((error) => {
					console.error(error);
				});
		} else {
			return axios
				.post(path, body)
				.then((response) => response.data)
				.catch((error) => {
					console.error(error);
				});
		}
	};

	postUploadSubtitle = (path, body, isUploadRequest = false, xCallback) => {
		//code is not used right now
		const instance = axios.create({
			//baseURL: baseUrl
		});

		let header = {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
			'Access-Control-Allow-Origin': '*',
			Host: 'upload.qbrick.com',
			Authorization: `Bearer ${LocalStorageService.getUploadAccessToken()}`,
			...(xCallback ?? {}),
		};

		instance.interceptors.request.use(
			(request) => {
				request.headers = header;
				return request;
			},
			(error) => {
				Promise.reject(error);
			}
		);

		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);

			return instance
				.post(path, formData, {
					onUploadProgress: function (progressEvent) {
						if (progressEvent.loaded === progressEvent.total) {
							store.dispatch(setIsAudioUploadFinished(Math.random()));
						}
					},
				})
				.then((data) => data)
				.catch((error) => {
					console.error(error);
				});
		} else {
			return axios
				.post(path, body)
				.then((response) => response.data)
				.catch((error) => {
					console.error(error);
				});
		}
	};

	postUploadImage = (path, body, isUploadRequest = false, xCallback) => {
		const instance = axios.create({
			//baseURL: baseUrl
		});
		let header = {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
			'Access-Control-Allow-Origin': '*',
			Host: 'upload.qbrick.com',
			Authorization: `Bearer ${LocalStorageService.getUploadAccessToken()}`,
			...(xCallback ?? {}),
		};

		instance.interceptors.request.use(
			(request) => {
				request.headers = header;
				return request;
			},
			(error) => {
				Promise.reject(error);
			}
		);

		if (isUploadRequest) {
			const formData = new FormData();
			formData.append('file', body.file);

			return instance
				.post(path, formData, {
					//return axios({methos: 'post', url:path, data: formData,
					onUploadProgress: function (progressEvent) {
						if (progressEvent.loaded === progressEvent.total) {
							store.dispatch(setIsAudioUploadFinished(Math.random()));
						}
					},
				})
				.then((data) => data)
				.catch((error) => {
					console.error(error);
				});
		} else {
			return axios
				.post(path, body)
				.then((response) => response.data)
				.catch((error) => {
					console.error(error);
				});
		}
	};

	postUrlEncoded = (path, body) => {
		this.enableRequestInterceptors();
		const formData = new URLSearchParams(); //not working in case of IE- added a polyfill which can be removed from package.json when not required
		formData.append('json', JSON.stringify(body));
		return axios
			.post(path, formData, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => response.data)
			.catch((error) => {
				console.error(error);
			});
	};

	postTranscribedText = (path, body) => {
		return fetch(path, { method: 'POST', body: JSON.stringify(body) })
			.then((_) => {})
			.catch((err) => {
				console.error('save failed: ', err);
			});
	};

	postTranscribedSub = (path, body) => {
		return fetch(path, { method: 'POST', body: body })
			.then((response) => response.data)
			.catch((err) => {
				console.error('save failed: ', err);
			});
	};

	getTotalCount = (path) => {
		this.enableRequestInterceptors();
		return axios
			.get(path)
			.then((response) => response.headers['x-total-count'])
			.catch((error) => {
				console.error(error);
			});
	};

	getPaginationData = (path, excludeAccessToken = false, signal) => {
		let instance = excludeAccessToken ? this.createInstance(excludeAccessToken) : axios;
		this.enableRequestInterceptors();

		return instance
			.get(path, { signal })
			.then((response) => ({ data: response.data, count: response.headers['x-total-count'] }))
			.catch((error) => {
				if (error.code === 'ERR_CANCELED') {
					throw new Error('Client Closed Request', { cause: 499 });
				}
			});
	};
}

export default new BackendClient();
