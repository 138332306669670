import _ from 'lodash';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import { Close, ExpandMore, SettingsOutlined, KeyboardArrowUp } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, showMessage } from '../../actions/globalActions';
import {
	FLOWS,
	INVALID_PROFILES,
	PART_OF_INVALID_PROFILES,
	UPLOAD_FAILED,
	messageTypes,
} from '../../constants/mediaConstants';
import { formatFileSize } from '../../services/publishScreenHelperService';
import {
	loadEncodingProfiles,
	loadUploadingFlows,
	preventUserFromCloseWindow,
	setQbrickStandardFlowsInReducer,
	setReplaceMediaContent,
	uploadMediaBegins,
} from '../../actions/publishActions';
import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { generateId } from '../../services/stringHelperService';
import { HeaderContext } from '../../context/HeaderContext';
import { setShowUploadMediasDialog } from '../../actions/uploadingActions';
import { getPlayIcon } from '../../services/mediaDisplayService';
import CatalogTree from '../../reusable/CatalogTree/CatalogTree';
import { getLastIndexCatalogId } from '../../services/locationPathServices';

import './UploadMediaDialog.scss';
import { Autocomplete } from '@material-ui/lab';
import { listAllUsers } from '../../actions/profileActions';

interface UploadMediaDialogProps {
	emails?: string[];
	onClose?: () => void;
	onUploadMedias?: () => void;
}

const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}$/;

const UploadMediaDialog: React.FC<UploadMediaDialogProps> = ({ onClose, emails, onUploadMedias }) => {
	const dispatch = useDispatch() as any;
	const { t: translator } = useTranslation();

	const { defaultAccountId, userPermissions, username } = useSelector((state) => (state as any).session);
	const { showUploadMediasDialog } = useSelector((state) => (state as any).uploadingReducer);
	const { selectedCatalogId, replaceMediaContent, qbrickStandardFlows } = useSelector(
		(state) => (state as any).publish
	);

	const { setUploadProgressStatus, setUploadingFiles } = useContext(HeaderContext);

	const [catalogListToggled, setCatalogListToggled] = useState<boolean>(false);
	const [hiddenEncodingField, setHiddenEncodingField] = useState<boolean>(false);
	const [selectedMediaItems, setSelectedMediaItems] = useState<any[]>([]);
	const [allEncodingProfiles, setAllEncodingProfiles] = useState<any[]>([]);
	const [selectedEncodingProfile, setSelectedEncodingProfiles] = useState<any | undefined>(undefined);
	const [selectedCatalog, setSelectedCatalog] = useState<string>(selectedCatalogId ?? getLastIndexCatalogId());
	const [emailNotificationToggled, setEmailNotificationToggled] = useState<boolean>(false);
	const [availableUserEmails, setAvailableUserEmails] = useState<any[]>([]);
	const [selectedUserEmails, setSelectedUserEmails] = useState<any[]>([]);

	const loadSelectedFiles = (files: any[], isAllowMultipleFiles: boolean) => {
		setSelectedMediaItems((prevState: any[]) => (isAllowMultipleFiles ? [...prevState, ...files] : [...files]));
	};

	const fetchEncodingProfiles = useCallback(() => {
		dispatch(loadEncodingProfiles(defaultAccountId)).then((data: any) => {
			if (data && data.map) {
				let encodingProfiles = data.sort((a: any, b: any) => a.value.localeCompare(b.value));
				if (encodingProfiles.length > 0) {
					// Filter all profiles that not uploading a video
					encodingProfiles = encodingProfiles.filter(
						(profile: any) =>
							!INVALID_PROFILES.includes(profile.value.toString()) &&
							!PART_OF_INVALID_PROFILES.find((p) => profile.value.toString().toLowerCase().includes(p))
					);
				}

				let defaultSelectedProfile = encodingProfiles.find((profile: any) =>
					profile.value.toString().toLowerCase().replaceAll(' ', '').includes('fullhighdefinition')
				);
				defaultSelectedProfile = defaultSelectedProfile
					? defaultSelectedProfile
					: encodingProfiles.find((profile: any) =>
							profile.value.toString().toLowerCase().replaceAll(' ', '').includes('highdefinition')
					  );
				defaultSelectedProfile = defaultSelectedProfile ? defaultSelectedProfile : data[0];

				setAllEncodingProfiles(encodingProfiles);
				setSelectedEncodingProfiles(defaultSelectedProfile);
			}
		});
	}, [defaultAccountId, dispatch]);

	const fetchUploadingFlows = useCallback(() => {
		dispatch(loadUploadingFlows(defaultAccountId)).then((data: any) => {
			const newQbrickStandardFlows =
				!data || typeof data === 'string'
					? []
					: (data?.flows ?? []).filter((f: any) => f.id === FLOWS.qbrickStandard);

			dispatch(setQbrickStandardFlowsInReducer(newQbrickStandardFlows));
			const qbrickStandardFlow = newQbrickStandardFlows.find((f: any) => f.id === FLOWS.qbrickStandard);
			if (qbrickStandardFlow?.id) {
				setSelectedEncodingProfiles({ value: qbrickStandardFlow.id });
			} else if (allEncodingProfiles.length === 0) {
				fetchEncodingProfiles();
			}
		});
	}, [defaultAccountId, dispatch, fetchEncodingProfiles, allEncodingProfiles.length]);

	const handleEncodingProfilesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedEncodingProfiles(allEncodingProfiles.find((p: any) => p.value === event.target.value));
	};

	const handleCatalogsChange = (catalogId: string) => {
		setSelectedCatalog(catalogId);
	};

	const getFileIdentification = (filename: string, isReplaceMediaContent = false) => {
		const id = generateId();
		return {
			title: `${isReplaceMediaContent ? "(Replacing media) " : ""}${filename}`,
			name: id + '.' + filename.split('.').pop(),
			id: id,
		};
	};

	const closeDialog = useCallback(() => {
		onClose?.();
		setSelectedMediaItems([]);
		dispatch(setShowUploadMediasDialog(false));
		dispatch(setReplaceMediaContent(undefined));
		setSelectedUserEmails(selectedUserEmails.filter(({ defaultEmail }) => defaultEmail).filter(Boolean));
	}, [dispatch, onClose, selectedUserEmails]);

	const beginTheMediaItemsUploadProcess = useCallback(() => {
		closeDialog();
		setUploadProgressStatus('uploading');
		dispatch(preventUserFromCloseWindow(true));

		const uploadedFiles: any[] = [];
		let counter = 0;
		let failedCounter = 0;
		const selectedEmails = selectedUserEmails.map(({ login }) => login).filter(Boolean);

		selectedMediaItems.forEach((file: any) => {
			const blob = file.slice(0, file.size, file.type, file.path);
			const { name, id, title } = getFileIdentification(file.name, !!replaceMediaContent?.mediaId);
			const blobFile = new File([blob], name, { type: file.type });
			(blobFile as any).title = title;
			(blobFile as any).media_id = id;
			(blobFile as any).filename = name;
			(blobFile as any).replaceMediaId = replaceMediaContent?.mediaId;

			dispatch(
				uploadMediaBegins(
					defaultAccountId,
					selectedEncodingProfile.value,
					selectedCatalog || '',
					undefined,
					blobFile,
					selectedEmails,
					file.name,
					replaceMediaContent
				)
			).then((data: any) => {
				counter++;
				if (data && data !== UPLOAD_FAILED) {
					uploadedFiles.push(blobFile);
				} else {
					failedCounter++;
				}

				if (counter === selectedMediaItems.length) {
					if (failedCounter > 0) {
						dispatch(showAlert(''));
						dispatch(showAlert(`${UPLOAD_FAILED} (${failedCounter})`, messageTypes.error));
					}
					if (uploadedFiles.length === 0) {
						setUploadProgressStatus('');
					}
					setUploadingFiles(uploadedFiles);
					replaceMediaContent &&
						dispatch(showAlert(translator('COMMON_YOUR_MEDIA_BEING_ENCODED'), messageTypes.info));
					onUploadMedias?.();
				}
			});
		});
	}, [closeDialog, setUploadProgressStatus, dispatch, selectedUserEmails, selectedMediaItems, replaceMediaContent, defaultAccountId, selectedEncodingProfile?.value, selectedCatalog, setUploadingFiles, translator, onUploadMedias]);

	useEffect(() => {
		setSelectedCatalog(selectedCatalogId);
	}, [selectedCatalogId]);

	useEffect(() => {
		if (!showUploadMediasDialog) {
			return;
		}
		fetchUploadingFlows();
	}, [defaultAccountId, fetchUploadingFlows, showUploadMediasDialog]);

	useEffect(() => {
		dispatch(listAllUsers(defaultAccountId)).then((data: any) => {
			if (!data) {
				return;
			}

			const emailsAvailable = data.map((user: any) => {
				if ((emails ?? []).findIndex((name) => name === user.login) > -1 || username === user.login) {
					return { ...user, defaultEmail: true, selected: true };
				}
				return user;
			});

			emails?.forEach((email: string) => {
				const emailIndex = emailsAvailable.findIndex(({ login }: any) => login === email);
				if (!emailIndex) {
					emailsAvailable.push({ login: email, defaultEmail: true, selected: true });
				}
			});

			setAvailableUserEmails(emailsAvailable);
			setSelectedUserEmails(emailsAvailable.filter(({ selected }: any) => selected));
		});
	}, [defaultAccountId, dispatch, emails, username]);

	return (
		<Dialog open={showUploadMediasDialog} fullWidth maxWidth={'sm'}>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{translator('HEADER_UPLOAD_MEDIA_DIALOG_LABEL')}</Typography>
				<IconButton onClick={closeDialog}>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<div className="dialogContentWrapper container">
					<Dropzone
						accept=".mp4, .m4a, .m4v, .mp3, .mov, .wmv, .jpg, .jpeg, .gif, .png, .bmp"
						// in case replacement only allow to select 1 file
						multiple={!replaceMediaContent}
						onDrop={(acceptedFiles, fileRejections) => {
							if (fileRejections.length > 0) {
								dispatch(
									showMessage(translator('COMMON_LABEL_NOT_SUPPORTED_FILES'), messageTypes.error)
								);
								return;
							}
							setHiddenEncodingField(acceptedFiles[0].type.includes('image'));

							loadSelectedFiles(acceptedFiles, !replaceMediaContent);
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()} />
								<div className={`mediaUploadContentArea`}>
									<Button className="defaultActionBtn">{translator('COMMON_LABEL_BROWSE')}</Button>
									<span style={{ marginBottom: '5px' }} className="mediaUploadContentArea__message">
										{translator('COMMON_BROWSE_OR_DRAG')}
									</span>
									<span className="mediaUploadContentArea__message">
										{translator('HEADER_UPLOAD_MEDIA_VIDEO_ACCEPTED_WARNING')}
									</span>
								</div>
							</div>
						)}
					</Dropzone>

					{selectedMediaItems && selectedMediaItems.length >= 1 && (
						<div className="selectedItemsContentWrapper">
							{selectedMediaItems &&
								selectedMediaItems.map((eachFile: any) => (
									<div className="eachSelectedItemWrapper" key={eachFile.name}>
										<div className="eachSelectedItemWrapper-label">
											<img className="playicon" src={getPlayIcon(eachFile.type)} />
											<label className="fileNameUploaded" title={eachFile.name}>
												{eachFile.name.length > 30
													? eachFile.name.substr(0, 30) + '...'
													: eachFile.name}
											</label>
											<label className="fileSizeUploaded">
												{formatFileSize(eachFile.size, 0)}
											</label>
										</div>
										<IconButton
											onClick={() =>
												setSelectedMediaItems((prevState) =>
													prevState.filter((file: any) => eachFile.name !== file.name)
												)
											}
										>
											<Close />
										</IconButton>
									</div>
								))}
						</div>
					)}
				</div>

				<div className="dialogContentWrapper">
					<div className="dialogContentWrapper_title">
						<SettingsOutlined />
						<label>{translator('HEADER_UPLOAD_MEDIA_ENCODING')}</label>
					</div>

					{!(hiddenEncodingField || qbrickStandardFlows.find((f: any) => f.id === FLOWS.qbrickStandard)) ? (
						<div className="sortingDropDownWrapper fullWidthControl customTopPadding">
							<select
								disabled={!allEncodingProfiles || allEncodingProfiles.length === 0}
								onChange={handleEncodingProfilesChange}
								className="sortingDropDown"
								value={selectedEncodingProfile?.value}
							>
								{allEncodingProfiles &&
									allEncodingProfiles.length !== 0 &&
									allEncodingProfiles.length !== 1 && <option value={''}>{'- Select -'}</option>}
								{allEncodingProfiles &&
									allEncodingProfiles.length > 0 &&
									allEncodingProfiles.map &&
									allEncodingProfiles.map((eachProfile: any) => (
										<option key={eachProfile.id} value={eachProfile.value}>
											{eachProfile.value}
										</option>
									))}
							</select>
						</div>
					) : (
						<span className="mediaUploadContentArea__message">
							{translator('HEADER_UPLOAD_MEDIA_NO_SELECT_ENCODING_INFO_MESSAGE')}
						</span>
					)}
				</div>

				<div className="dialogContentWrapper">
					<div
						className="dialogContentWrapper_title dialogContentWrapper_title--clickable"
						onClick={() => setCatalogListToggled((prevState) => !prevState)}
					>
						<label>{translator('HEADER_UPLOAD_MEDIA_SAVE_TO_CATALOG')}</label>
						{catalogListToggled ? <KeyboardArrowUp /> : <ExpandMore />}
					</div>
					<div
						id="catalogContainer"
						className="customTopPadding"
						style={{ display: !catalogListToggled ? 'none' : '' }}
					>
						<CatalogTree
							handleCatalogsChange={handleCatalogsChange}
							showNoCatalogOption={true}
							preselectedCatalog={selectedCatalog}
						/>
					</div>
				</div>
				<div
					className={`uploadDialogEmailSection ${emailNotificationToggled ? 'expanded' : ''}`}
					onClick={() => {
						if (emailNotificationToggled) {
							return;
						}
						setEmailNotificationToggled((prevState) => !prevState);
					}}
				>
					<div className="uploadEmailContent">
						<div className="emailNotificationTitle">
							<label>{translator('HEADER_UPLOAD_MEDIA_EMAIL_NOTIFICATION')}</label>
							<IconButton
								size="small"
								onClick={(event) => {
									event.stopPropagation();
									setEmailNotificationToggled((prevState) => !prevState);
								}}
							>
								{emailNotificationToggled ? (
									<KeyboardArrowUp htmlColor="#909CA5" />
								) : (
									<ExpandMore htmlColor="#909CA5" />
								)}
							</IconButton>
						</div>
						<div
							className="emailNotificationInput"
							style={{ display: !emailNotificationToggled ? 'none' : '' }}
						>
							<Typography style={{ fontWeight: 'bold' }}>
								{translator('HEADER_UPLOAD_MEDIA_NOTIFY_BY_EMAIL')}
							</Typography>

							<div className="emailNotificationTextArea">
								<Autocomplete
									multiple
									limitTags={2}
									freeSolo
									size="small"
									filterSelectedOptions
									options={availableUserEmails}
									value={selectedUserEmails}
									fullWidth
									onChange={(event: any, newValue, reason) => {
										if (reason === 'create-option') {
											const newEmail = event.target.value;

											const isNewEmailExisted = selectedUserEmails.find(
												({ login }) => login === newEmail
											);

											if (isNewEmailExisted) {
												return;
											}

											const emailFromAvailableList = availableUserEmails.find(
												({ login }) => login === newEmail
											);

											if (emailFromAvailableList) {
												setSelectedUserEmails([...selectedUserEmails, emailFromAvailableList]);
												return;
											}

											EMAIL_REGEXP.test(newEmail) &&
												setSelectedUserEmails([
													...selectedUserEmails,
													{ login: newEmail, selected: true },
												]);
											return;
										}

										if (reason === 'remove-option') {
											const [item] = _.difference(selectedUserEmails, newValue) ?? [];

											if (item?.defaultEmail) {
												return;
											}
										}

										if (reason === 'clear') {
											setSelectedUserEmails(
												selectedUserEmails.filter(({ defaultEmail }) => defaultEmail)
											);
											return;
										}
										setSelectedUserEmails(newValue);
									}}
									renderTags={(tagValue, getTagProps) =>
										tagValue.map((option, index) => (
											<Chip
												key={option.login}
												label={option.login}
												{...getTagProps({ index })}
												size="small"
												style={{
													backgroundColor: '#126CFC1A',
													color: '#000000',
													borderRadius: 4,
												}}
												deleteIcon={
													option.defaultEmail ? (
														<></>
													) : (
														<ClearIcon style={{ color: '#000000' }} />
													)
												}
											/>
										))
									}
									getOptionLabel={({ login }: any) => login}
									renderInput={(params) => <TextField {...params} variant="outlined" />}
									style={{ width: 360 }}
								/>
							</div>
						</div>
					</div>
				</div>
			</DialogContent>

			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
						<Button
							variant="contained"
							className="defaultActionBtn"
							onClick={() => {
								if (!checkPermissionGrantedFor(userPermissions, 'ingest')) {
									dispatch(
										showMessage(
											translator('HEADER_UPLOAD_MODAL_FORBIDDEN_UPLOAD_FILES_NOTIFICATION'),
											messageTypes.error
										)
									);
									return;
								}
								beginTheMediaItemsUploadProcess();
							}}
							disabled={!(selectedEncodingProfile && selectedMediaItems?.length > 0)}
						>
							{translator('COMMON_UPLOAD')}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default UploadMediaDialog;
