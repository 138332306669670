import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { showNotification } from '../../../../../actions/notificationActions';
import {
	deleteRendition,
	startSubtitleUploadProcess,
	startAudioUploadProcess,
	removeSubtitle,
	removeAudio,
	getMediaQueryDetails,
	getAllCurrentJobs,
	setUploadingProcessActive,
} from '../../../../../actions/publishActions';
import FileTable from './FileTable/FileTable';
import trashcan from '../../../../../assets/images/trashcan.svg';
import {
	renditionTypes,
	messageTypes,
	tableSortTypes,
	renditionGroupTypes,
	mediaNames,
	UPLOAD_FAILED,
	UPLOADING_FILE,
	mediaTypes,
} from './../../../../../constants/mediaConstants';
import eye from '../../../../../assets/images/eye.svg';
import download from '../../../../../assets/images/download.svg';
import dots from '../../../../../assets/images/dots.svg';
import InformationDialog from './InformationDialog/InformationDialog';
import UploadSubtitleDialog from './UploadSubtitleDialog/UploadSubtitleDialog';
import UploadAudioDialog from './UploadAudioDialog/UploadAudioDialog';
import './Files.scss';
import FileTableListMenu from './FileTableListMenu/FileTableListMenu';
import { FileDeleteDialog } from './FileDeleteDialog';
import _ from 'lodash';
import { languageOptionsDropDownMenu } from '../../../../../constants/languageConstants';
import { generateId } from '../../../../../services/stringHelperService';
import { isRequestFail } from '../../../../../services/inspectScreenHelperService';
import { getDetails } from '../../../../../services/mediaDisplayService';
import { showMessage } from '../../../../../actions/globalActions';
import Radio from '@material-ui/core/Radio';
import { joinClassNames } from '../../../../../services/elementHelperService';
import { Check } from '@material-ui/icons';

const idList = {
	LINK: 'link',
	PREVIEW: 'preview',
	SIZE: 'size',
	RESOLUTION: 'resolution',
	DOWNLOAD: 'download',
	DELETE: 'delete',
	BITRATE: 'bitrate',
	HLS_ENABLED: 'hls_enabled',
	CLOSED_CAPTION: 'closed_caption',
	AUDIO_DESCRIPTION: 'audio_description',
};

const MORE = 'more';

class Files extends Component {
	state = {
		imageData: [],
		videoData: [],
		indexData: [],
		audioData: [],
		subtitleData: [],
		allLanguages: [],
		openInfoDialog: false,
		selectedRow: {},
		menuAnchor: null,
		deleteItemDialogStatus: false,
		openSubtitleDialog: false,
		openAudioDialog: false,
		selectedSubtitleLanguage: '',
		selectedAudioLanguage: '',
		isVisualImpairedAudio: false,
		isHearingImpairedSub: false,
		languageSelected: false,
		selectedHlsValue: '',
	};

	UNSAFE_componentWillMount() {
		this.getData(this.props.mediaDetails);
		this.setState({ allLanguages: languageOptionsDropDownMenu });
	}

	componentDidMount() {
		if (this.props.onFilesComponentLoaded) {
			this.props.onFilesComponentLoaded();
		}
	}

	componentDidUpdate(prevProps) {
		const { t } = this.props;
		if (prevProps.isAudioUploadFinished != this.props.isAudioUploadFinished) {
			if (this.props.isAudioUploadFinished) {
				this.props.showMessage(t('COMMON_FILE_UPLOADED_SUCCESSFULLY'), messageTypes.success);
				setTimeout(() => {
					this.fetchMediaDetails((details) => {
						this.getData(details);
					});
				}, 3000);
			}
		}
	}

	getHeaders = () => {
		const { t, mediaType } = this.props;
		const isTrashedMedia = mediaType === mediaTypes.trashedMedias;
		let headers = {
			videoHeader: [
				{ id: idList.LINK, text: t('COMMON_VIDEOS') },
				{ id: idList.PREVIEW, text: t('COMMON_PREVIEW') },
				{ id: idList.SIZE, text: t('COMMON_SIZE') },
				{ id: idList.RESOLUTION, text: t('COMMON_RESOLUTION') },
				{ id: idList.BITRATE, text: t('COMMON_BITRATE'), sort: tableSortTypes.DESC },
				{ id: MORE, text: '' },
			],
			imageHeader: [
				{ id: idList.LINK, text: t('COMMON_IMAGES') },
				{ id: idList.PREVIEW, text: t('COMMON_PREVIEW') },
				{ id: idList.SIZE, text: t('COMMON_SIZE'), sort: tableSortTypes.DESC },
				{ id: idList.RESOLUTION, text: t('COMMON_RESOLUTION') },
				{ id: 'dummy', text: '' }, // filling dummy data to maintain UI consistency
				{ id: MORE, text: '' },
			],
			indexHeader: [
				{ id: idList.LINK, text: t('COMMON_INDEX') },
				{ id: idList.SIZE, text: t('COMMON_SIZE'), sort: tableSortTypes.DESC },
				{ id: 'dummy', text: '' }, // filling dummy data to maintain UI consistency
				{ id: 'dummy', text: '' }, // filling dummy data to maintain UI consistency
				{ id: 'dummy', text: '' }, // filling dummy data to maintain UI consistency
				{ id: MORE, text: '' },
			],
			subtitleHeader: [
				{ id: idList.LINK, text: t('COMMON_SUBTITLES') },
				{ id: idList.DOWNLOAD, text: t('COMMON_DOWNLOAD') },
				{ id: idList.LANGUAGE, text: t('COMMON_LANGUAGE'), sort: tableSortTypes.DESC },
				{ id: idList.CLOSED_CAPTION, text: t('COMMON_CLOSED_CAPTION') },
				{ id: idList.DELETE, text: isTrashedMedia ? '' : t('COMMON_DELETE') },
				{ id: MORE, text: '' },
			],
			audioHeader: [
				{ id: idList.LINK, text: t('SETTINGS_PRESET_CONFIG_LANGUAGE_SETTINGS_AUDIO_TRACKS') },
				{ id: idList.PREVIEW, text: t('COMMON_PREVIEW') },
				{ id: idList.LANGUAGE, text: t('COMMON_LANGUAGE') },
				{ id: idList.AUDIO_DESCRIPTION, text: t('COMMON_AUDIO_DESCRIPTION') },
				{ id: idList.DELETE, text: isTrashedMedia ? '' : t('COMMON_DELETE') },
				{ id: 'dummy', text: '' }, // filling dummy data to maintain UI consistency
				{ id: MORE, text: '' },
			],
		};
		return headers;
	};

	checkIfFilesExists = () => {
		const { mediaDetails } = this.props;
		let resources = mediaDetails.asset ? mediaDetails.asset.resources : '';
		if (!resources) {
			return false;
		} else {
			return true;
		}
	};

	getSortedData = (list, property) => {
		let sortedArray = list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
		return sortedArray;
	};

	getSortedLanguageData = (list, property) => {
		return list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
	};

	fetchMediaDetails = (callback) => {
		const { defaultAccountId, mediaDetails, t, mediaName } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
					messageTypes.error
				);
				return;
			}
			let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
			callback(customMediaInfo);
		});
	};

	getData = (mediaDetails) => {
		let images = [];
		let videos = [];
		let indexes = [];
		let subtitles = [];
		let audios = [];
		mediaDetails &&
			mediaDetails.asset &&
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.map((resource) => {
				if (resource && resource.renditions) {
					resource.renditions.map((rendition) => {
						let link = rendition.links ? rendition.links[0].href : '';
						let arr = link ? link.split('/') : rendition.path ? rendition.path.split('/') : [];
						let lastName = arr.length > 0 ? arr[arr.length - 1] : '';
						let defaultName = '...' + (link ? link.slice(-17) : '');
						let name = lastName || defaultName;
						let genericObj = {
							resourceId: resource.id,
							renditionId: rendition.id,
							name: name,
							link: link,
							rel: resource.rel ?? [],
						};
						let customObj = {
							...genericObj,
							...rendition,
						};
						switch (rendition.type) {
							case renditionTypes.AUDIO:
								audios.push(customObj);
								break;
							case renditionTypes.VIDEO: {
								let tempVideoObj = {};
								if (rendition.videos && rendition.videos.length > 0) {
									tempVideoObj = {
										...rendition.videos[0],
									};
								}
								let videoObj = {
									...genericObj,
									...rendition,
									...tempVideoObj,
								};
								videos.push(videoObj);
								break;
							}

							case renditionTypes.IMAGE:
								images.push(customObj);
								break;

							case renditionTypes.INDEX:
								indexes.push(customObj);
								break;
							case renditionTypes.SUBTITLE:
								subtitles.push(customObj);
								break;
							default:
								break;
						}
					});
				}
			});
		videos = this.getSortedData(videos, 'bitrate');
		images = this.getSortedData(images, 'size');
		audios = this.getSortedData(audios, 'size');
		indexes = this.getSortedData(indexes, 'size');
		subtitles = this.getSortedLanguageData(subtitles, 'created');
		/*this.setState({
            audioData: audios,
            imageData: images,
            videoData: videos,
            indexData: indexes,
            subtitleData: subtitles
        })*/
		if (JSON.stringify(this.state.audioData) !== JSON.stringify(audios)) {
			this.setState({
				audioData: audios,
			});
		}
		if (JSON.stringify(this.state.imageData) !== JSON.stringify(images)) {
			this.setState({
				imageData: images,
			});
		}
		if (JSON.stringify(this.state.videoData) !== JSON.stringify(videos)) {
			this.setState({
				videoData: videos,
			});
		}
		if (JSON.stringify(this.state.indexData) !== JSON.stringify(indexes)) {
			this.setState({
				indexData: indexes,
			});
		}

		if (JSON.stringify(this.state.subtitleData) !== JSON.stringify(subtitles)) {
			this.setState({
				subtitleData: subtitles,
			});
		}
	};

	handleRemoveTheSelectedMediaItem = (itemName) =>
		this.setState({
			selectedMediaItems: this.state.selectedMediaItems.filter((eachFile) => eachFile.name != itemName),
		});

	handleSort = (tableType) => {
		const { videoData, audioData, imageData, indexData, subtitleData } = this.state;
		switch (tableType) {
			case renditionTypes.VIDEO:
				this.setState({
					videoData: videoData.reverse(),
				});
				break;
			case renditionTypes.AUDIO:
				this.setState({
					audioData: audioData.reverse(),
				});
				break;
			case renditionTypes.IMAGE:
				this.setState({
					imageData: imageData.reverse(),
				});
				break;
			case renditionTypes.INDEX:
				this.setState({
					indexData: indexData.reverse(),
				});
				break;
			case renditionTypes.SUBTITLE:
				this.setState({
					subtitleData: subtitleData.reverse(),
				});
				break;
			default:
				break;
		}
	};

	getUploadIcon = () => {
		return (
			<svg
				style={{
					width: '15px',
					height: '15px',
					fill: '#126CFC',
				}}
				className="dateTimeIcon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 27.53 27.15"
			>
				<defs />
				<title>Upload</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path className="cls-1" d="M15.26,2.74V20.36a1.5,1.5,0,0,1-3,0V2.74a1.5,1.5,0,0,1,3,0Z" />
						<path
							className="cls-1"
							d="M21.52,7.48a1.48,1.48,0,0,1-.42,1A1.5,1.5,0,0,1,19,8.56l-5.22-5-5.21,5A1.5,1.5,0,0,1,6.47,6.39l6.26-6a1.49,1.49,0,0,1,2.07,0l6.25,6A1.51,1.51,0,0,1,21.52,7.48Z"
						/>
						<path
							className="cls-1"
							d="M24.79,18.38v3.31a2.73,2.73,0,0,1-2.73,2.73H5.47a2.73,2.73,0,0,1-2.73-2.73V18.38H0v3.31a5.47,5.47,0,0,0,5.47,5.46H22.06a5.47,5.47,0,0,0,5.47-5.46V18.38Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	getAudiosTable = () => {
		const { t, mediaDetails, mediaType, showNotification } = this.props;
		const { audioData } = this.state;
		if (mediaDetails.type != renditionTypes.IMAGE) {
			return (
				<>
					<FileTable
						handleSort={this.handleSort}
						getCells={this.getCells}
						tableType={renditionTypes.AUDIO}
						className="filesTable"
						tableHeaderData={this.getHeaders().audioHeader}
						tableData={audioData}
						handleMenuOpen={this.handleMenuOpen}
					/>{' '}
					<br />
					<div className="uploadSection">
						<a
							className={joinClassNames(
								'uploadButton',
								mediaType === mediaTypes.trashedMedias ? 'btn--disabled' : undefined
							)}
							onClick={() => {
								if (mediaType === mediaTypes.trashedMedias) {
									showNotification(t('COMMON_RESTORE_TO_CHANGE'));
									return;
								}
								this.handleShowUploadAudioDialog();
							}}
						>
							{this.getUploadIcon()}
							<span>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_UPLOAD_NEW_AUDIO')}</span>
						</a>
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	getVideosTable = () => {
		const { mediaDetails } = this.props;
		const { videoData } = this.state;

		if (mediaDetails.type != renditionTypes.IMAGE) {
			return (
				<>
					<FileTable
						handleSort={this.handleSort}
						getCells={this.getCells}
						tableType={renditionTypes.VIDEO}
						className="filesTable"
						tableHeaderData={this.getHeaders().videoHeader}
						tableData={videoData}
						handleMenuOpen={this.handleMenuOpen}
					/>
					{/* <br /> <div className='uploadSection'> <a className='uploadButton'> {this.getUploadIcon()} <span> {t('UPLOAD_NEW_VIDEO')}  </span> </a> </div> */}
				</>
			);
		} else {
			return null;
		}
	};

	loadTheSelectedMediaItems = (files) => {
		const { selectedMediaItems } = this.state;
		if (selectedMediaItems && selectedMediaItems.length > 0) {
			const newFilesSet = files.map((eachNewFile) => {
				const selectedIndex = selectedMediaItems.findIndex((el) => el && el.name === eachNewFile.name);
				if (selectedIndex === -1) {
					return eachNewFile;
				}
			});
			this.setState({ selectedMediaItems: this.state.selectedMediaItems.concat(newFilesSet) });
		} else {
			this.setState({ selectedMediaItems: files });
		}
	};

	getImagesTable = () => {
		const { imageData } = this.state;
		if (1) {
			return (
				<>
					<FileTable
						handleSort={this.handleSort}
						getCells={this.getCells}
						tableType={renditionTypes.IMAGE}
						className="filesTable"
						tableHeaderData={this.getHeaders().imageHeader}
						tableData={imageData}
						handleMenuOpen={this.handleMenuOpen}
					/>
					{/*   <br /> <div className='uploadSection'> <a className='uploadButton'> {this.getUploadIcon()} <span> {t('UPLOAD_NEW_IMAGE')}  </span> </a> </div> */}
				</>
			);
		} else {
			return null;
		}
	};

	getIndexTable = () => {
		const { indexData } = this.state;
		if (1) {
			return (
				<>
					<FileTable
						handleSort={this.handleSort}
						getCells={this.getCells}
						tableType={renditionTypes.INDEX}
						className="filesTable"
						tableHeaderData={this.getHeaders().indexHeader}
						tableData={indexData}
						handleMenuOpen={this.handleMenuOpen}
					/>
					{/*  <br /> <div className='uploadSection'> <a className='uploadButton'> {this.getUploadIcon()}  <span> {t('UPLOAD_NEW_INDEX')}  </span> </a> </div> */}
				</>
			);
		} else {
			return null;
		}
	};

	getSubtitleTable = () => {
		const { t, mediaDetails, mediaType, showNotification } = this.props;
		const { subtitleData, selectedHlsValue } = this.state;

		if (mediaDetails.type != renditionTypes.IMAGE) {
			return (
				<>
					<FileTable
						handleSort={this.handleSort}
						getCells={this.getCells}
						tableType={renditionTypes.SUBTITLE}
						className="filesTable"
						tableHeaderData={this.getHeaders().subtitleHeader}
						tableData={subtitleData}
						selectedHlsValue={selectedHlsValue}
						handleMenuOpen={this.handleMenuOpen}
					/>
					<br />
					<div className="uploadSection">
						<a
							className={joinClassNames(
								'uploadButton',
								mediaType === mediaTypes.trashedMedias ? 'btn--disabled' : undefined
							)}
							onClick={() => {
								if (mediaType === mediaTypes.trashedMedias) {
									showNotification(t('COMMON_RESTORE_TO_CHANGE'));
									return;
								}
								this.handleShowUploadSubtitleDialog();
							}}
						>
							{this.getUploadIcon()}
							<span>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_UPLOAD_NEW_SUBTITLE')}</span>
						</a>
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	getCells = (id, rowData, selectedHlsValue) => {
		const { t, mediaType } = this.props;

		const isTrashedMedia = mediaType === mediaTypes.trashedMedias;

		switch (id) {
			case idList.LINK:
				if (rowData.type == renditionTypes.SUBTITLE) {
					return (
						<div
							className={
								rowData.type == renditionTypes.IMAGE ||
								rowData.type == renditionTypes.VIDEO ||
								rowData.type == renditionTypes.AUDIO ||
								rowData.type == renditionTypes.INDEX ||
								rowData.type == renditionTypes.SUBTITLE // index and subtitles can be removed in this case
									? 'videoAudioImageInfoDiv'
									: 'infoDiv'
							}
						>
							<span className="subtitleTitle">{rowData.name}</span>
						</div>
					);
				} else {
					return (
						<>
							<div
								className={
									rowData.type == renditionTypes.IMAGE ||
									rowData.type == renditionTypes.VIDEO ||
									rowData.type == renditionTypes.AUDIO ||
									rowData.type == renditionTypes.INDEX ||
									rowData.type == renditionTypes.SUBTITLE // index and subtitles can be removed in this case
										? 'videoAudioImageInfoDiv'
										: 'infoDiv'
								}
							>
								<a
									className="fileTitle"
									onClick={() => {
										this.showInfoDialog(rowData);
									}}
								>
									{rowData.name}
								</a>
							</div>
						</>
					);
				}
			case idList.PREVIEW:
				return (
					<>
						<div
							className="previewImage"
							onClick={() => {
								this.showPreview(rowData);
							}}
						>
							<a>
								<img className="previewIcon" src={eye} />
								<span> {t('COMMON_LINK')} </span>{' '}
							</a>
						</div>
					</>
				);

			case idList.SIZE:
				return (
					<>
						<span>{rowData.size}</span>
					</>
				);

			case idList.LANGUAGE:
				if (rowData.type == renditionTypes.AUDIO) {
					return (
						<>
							<span>{rowData.audios[0].language}</span>
						</>
					);
				} else {
					return (
						<>
							<span>{rowData.language}</span>
						</>
					);
				}

			case idList.AUDIO_DESCRIPTION:
				if (rowData.type == renditionTypes.AUDIO && rowData.rel?.[0] === 'AUDIO_DESCRIPTION') {
					return <Check />;
				}
				return <></>;

			case idList.CLOSED_CAPTION:
				if (rowData.type == renditionTypes.SUBTITLE && rowData.language?.includes('-cc')) {
					return <Check />;
				}
				return <></>;

			case idList.RESOLUTION: {
				let resolution = (rowData.width || '') + ' x ' + (rowData.height || '');
				return (
					<>
						<span> {resolution} </span>
					</>
				);
			}

			case idList.DOWNLOAD:
				return (
					<>
						<a href={rowData.link + '?download=true'} download>
							<img className="downloadIcon" src={download} />
						</a>
					</>
				);

			case idList.HLS_ENABLED:
				return (
					!isTrashedMedia && (
						<div className="subtitleRadioButton">
							<Radio
								checked={selectedHlsValue === decodeURIComponent(rowData.name)}
								onClick={this.handleHlsSubtitleChange}
								value={decodeURIComponent(rowData.name)}
								name="radio-buttons"
								inputProps={{ 'aria-label': decodeURIComponent(rowData.name) }}
								color="default"
							/>
						</div>
					)
				);

			case idList.DELETE:
				return (
					<>
						{!isTrashedMedia && (
							<img
								className={joinClassNames('deleteIcon')}
								src={trashcan}
								onClick={() => this.handleDelete(rowData)}
							/>
						)}
					</>
				);

			case idList.BITRATE:
				return (
					<>
						<span> {rowData.bitrate} </span>
					</>
				);

			case MORE:
				return (
					<>
						{!isTrashedMedia && (
							<button className="moreButton" onClick={(e) => this.handleMenuOpen(e, rowData)}>
								<img src={dots} />
							</button>
						)}
					</>
				);
			default:
				return <></>;
		}
	};

	showPreview = (rowData) => {
		window.open(rowData.link);
	};

	showInfoDialog = (rowData) => {
		if (
			rowData.type == renditionTypes.AUDIO ||
			rowData.type == renditionTypes.VIDEO ||
			rowData.type == renditionTypes.IMAGE ||
			rowData.type == renditionTypes.INDEX ||
			rowData.type == renditionTypes.SUBTITLE // index and subtitles can be removed in this case
		) {
			this.setState({
				openInfoDialog: true,
				selectedRow: rowData,
			});
		}
	};

	closeInformationDialog = () => {
		this.setState({
			openInfoDialog: false,
		});
		this.resetSelectedRow();
	};

	renderInfoDialog = (_) => {
		const { t } = this.props;
		const { openInfoDialog, selectedRow } = this.state;
		if (openInfoDialog) {
			return (
				<>
					<InformationDialog
						t={t}
						open={openInfoDialog}
						selectedRow={selectedRow}
						closeInformationDialog={this.closeInformationDialog}
					/>
				</>
			);
		} else {
			return null;
		}
	};

	renderUploadSubtitleDialog = (_) => {
		const { t } = this.props;
		const { openSubtitleDialog } = this.state;
		if (openSubtitleDialog) {
			return (
				<UploadSubtitleDialog
					t={t}
					state={this.state}
					getUploadDialogStyle={this.getUploadDialogStyle}
					loadTheSelectedMediaItems={this.loadTheSelectedMediaItems}
					handleSubtitleLanguageChange={this.handleSubtitleLanguageChange}
					beginTheMediaItemsUploadProcess={this.beginTheMediaItemsUploadProcess}
					handleRemoveTheSelectedMediaItem={this.handleRemoveTheSelectedMediaItem}
					handleHearingImpairedChange={this.handleHearingImpairedChange}
					open={openSubtitleDialog}
					closeInformationDialog={this.handleHideUploadSubtitleDialog}
				/>
			);
		} else {
			return null;
		}
	};

	renderUploadAudioDialog = (_) => {
		const { t } = this.props;
		const { openAudioDialog } = this.state;
		if (openAudioDialog) {
			return (
				<UploadAudioDialog
					t={t}
					state={this.state}
					getUploadDialogStyle={this.getUploadDialogStyle}
					loadTheSelectedMediaItems={this.loadTheSelectedMediaItems}
					handleAudioLanguageChange={this.handleAudioLanguageChange}
					handleVisualImpairedChange={this.handleVisualImpairedChange}
					beginAudioItemsUploadProcess={this.beginAudioItemsUploadProcess}
					handleRemoveTheSelectedMediaItem={this.handleRemoveTheSelectedMediaItem}
					open={openAudioDialog}
					closeInformationDialog={this.handleHideUploadAudioDialog}
				/>
			);
		} else {
			return null;
		}
	};

	beginAudioItemsUploadProcess = (_) => {
		const { selectedRow } = this.state;
		const { defaultAccountId, t, mediaDetails, setUploadingProcessActive } = this.props;
		const { selectedAudioLanguage, selectedMediaItems, isVisualImpairedAudio } = this.state;

		let assetId = mediaDetails.asset ? mediaDetails.asset.id : '';
		let mediaID = mediaDetails.id ? mediaDetails.id : '';

		if (!selectedAudioLanguage) {
			this.props.showNotification(t('COMMON_NO_LANGUAGE_SELECTED'));
			return;
		}

		setUploadingProcessActive(UPLOADING_FILE);
		selectedMediaItems.forEach((file) => {
			this.props
				.startAudioUploadProcess(
					defaultAccountId,
					file,
					selectedAudioLanguage,
					assetId,
					mediaID,
					selectedRow.resourceId,
					isVisualImpairedAudio
				)
				.then((data) => {
					setTimeout(() => setUploadingProcessActive(''), 1000);
					if (!data || data === UPLOAD_FAILED) {
						return;
					}
					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_AUDIO_TRACK_UPLOADING'),
						messageTypes.success
					);
				});
		});
		this.handleHideUploadAudioDialog();
	};

	getRandomFileName = (filename) => {
		return generateId() + '.' + filename.split('.').pop();
	};

	beginTheMediaItemsUploadProcess = (_) => {
		const { defaultAccountId, t, mediaDetails, setUploadingProcessActive } = this.props;
		const { selectedSubtitleLanguage, selectedMediaItems, isHearingImpairedSub } = this.state;
		let assetId = mediaDetails.asset ? mediaDetails.asset.id : '';
		let mediaID = mediaDetails.id ? mediaDetails.id : '';

		if (!selectedSubtitleLanguage) {
			this.props.showNotification(t('COMMON_NO_LANGUAGE_SELECTED'));
			return;
		}

		let languageToPass = isHearingImpairedSub ? selectedSubtitleLanguage + '-cc' : selectedSubtitleLanguage;
		setUploadingProcessActive(UPLOADING_FILE);
		selectedMediaItems.forEach((file) => {
			var blob = file.slice(0, file.size, file.type, file.path);
			var blobFile = new File([blob], this.getRandomFileName(file.name), { type: file.type });
			blobFile.title = file.name;
			blobFile.filename = file.name;

			let resourceId = generateId();
			this.props
				.startSubtitleUploadProcess(defaultAccountId, blobFile, languageToPass, assetId, mediaID, resourceId)
				.then((res) => {
					setTimeout(() => setUploadingProcessActive(''), 1000);
					if ((res && res.message) || !res || res === UPLOAD_FAILED) {
						return;
					}

					if (res && res.data && typeof res.data === 'string') {
						let uid = res.data.split('uid:');
						uid = uid.length > 0 ? uid[1] : '';
						setTimeout(() => {
							this.trackUploadStatus(uid, renditionTypes.SUBTITLE);
						}, 500);
					}
				});
		});
		this.handleHideUploadSubtitleDialog();
	};

	trackUploadStatus = (uid, type) => {
		const { defaultAccountId, t } = this.props;
		let file = '';
		this.props
			.getAllCurrentJobs(defaultAccountId)
			.then((data) => {
				if (data && data.length > 0) {
					file = _.find(data, (eachData) => {
						return eachData.uid.trim() === uid.trim();
					});
				}
				if (file) {
					setTimeout(() => {
						this.trackUploadStatus(uid, type);
					}, 500);
				} else {
					if (type === renditionTypes.SUBTITLE) {
						this.props.showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_SUBTITLE_UPLOADED')
						);
					}
					setTimeout(() => {
						this.fetchMediaDetails((details) => {
							this.getData(details);
						});
					}, 3000);
				}
			})
			.catch((error) => error);
	};

	handleHlsSubtitleChange = (event) => {
		if (event.target.value === this.state.selectedHlsValue) {
			this.setState({
				selectedHlsValue: '',
			});
		} else {
			this.setState({
				selectedHlsValue: String(event.target.value),
			});
		}
	};

	handleAudioLanguageChange = (e) => {
		let obj = languageOptionsDropDownMenu.find((o) => o.value === e.target.options[e.target.selectedIndex].value);

		this.setState({ selectedAudioLanguage: obj.languageCode, languageSelected: true });
	};

	handleVisualImpairedChange = (value) => {
		this.setState({ isVisualImpairedAudio: value });
	};

	handleHearingImpairedChange = (value) => {
		this.setState({ isHearingImpairedSub: value });
	};

	handleHideUploadAudioDialog = (_) => {
		this.setState({ openAudioDialog: false, selectedMediaItems: '' });
		this.resetSelectedRow();
	};

	handleSubtitleLanguageChange = (e) => {
		let obj = languageOptionsDropDownMenu.find((o) => o.value === e.target.options[e.target.selectedIndex].value);

		this.setState({ selectedSubtitleLanguage: obj.languageCode, languageSelected: true });
	};

	handleHideUploadSubtitleDialog = (_) => {
		this.setState({ openSubtitleDialog: false, selectedMediaItems: '' });
		this.resetSelectedRow();
	};

	handleShowUploadSubtitleDialog = (_) => this.setState({ openSubtitleDialog: true });

	handleShowUploadAudioDialog = (_) => this.setState({ openAudioDialog: true });

	getUploadDialogStyle = (_) => ({ paddingLeft: 53, paddingTop: 0, paddingRight: 53, paddingBottom: 0 });

	hideDeleteDialog = (_) => {
		this.setState({
			deleteItemDialogStatus: false,
		});
	};

	resetSelectedRow = (_) => {
		this.setState({
			selectedRow: {},
		});
	};

	handleDelete = (data) => {
		this.setState({
			deleteItemDialogStatus: true,
			selectedRow: data,
		});
	};

	loadTheSelectedMediaItems = (files) => {
		const { selectedMediaItems } = this.state;
		if (selectedMediaItems && selectedMediaItems.length > 0) {
			const newFilesSet = files.map((eachNewFile) => {
				const selectedIndex = _.findIndex(selectedMediaItems, (el) => el && el.name === eachNewFile.name);
				if (selectedIndex === -1) {
					return eachNewFile;
				}
			});
			this.setState({ selectedMediaItems: this.state.selectedMediaItems.concat(newFilesSet) });
		} else {
			this.setState({ selectedMediaItems: files });
		}
	};

	renderDeleteDialog = (_) => {
		const { t, defaultAccountId } = this.props;
		const { deleteItemDialogStatus } = this.state;
		if (deleteItemDialogStatus) {
			return (
				<FileDeleteDialog
					t={t}
					state={this.state}
					defaultAccountId={defaultAccountId}
					getUploadDialogStyle={this.getUploadDialogStyle}
					deleteSelection={this.deleteFile}
					hideDeleteDialog={this.hideDeleteDialog}
				/>
			);
		} else {
			return null;
		}
	};

	removeRenditionFromTable = (selectedRow) => {
		let dataWithType = this.getTableDataWithType(selectedRow.type);
		let newTable = dataWithType.table;
		if (selectedRow.type === renditionTypes.AUDIO || selectedRow.type === renditionTypes.SUBTITLE) {
			newTable = dataWithType.table.filter((res) => res.resourceId !== selectedRow.resourceId);
		} else {
			newTable = dataWithType.table.filter((res) => res.id !== selectedRow.id);
		}
		this.setState({
			[dataWithType.stateTableName]: newTable,
		});
	};

	testCode = (selectedRow) => {
		this.removeRenditionFromTable(selectedRow);
		this.hideDeleteDialog();
		return; //add for testing without api call
	};

	deleteFile = () => {
		const { selectedRow } = this.state;
		const { defaultAccountId, mediaDetails, t } = this.props; // working code
		let assetId = mediaDetails.asset ? mediaDetails.asset.id : '';
		let renditionGroup = this.getRenditionGroup(selectedRow.type);
		this.hideDeleteDialog();
		// this.testCode(selectedRow) //to be removed its only for testing UI calls
		// return;//to be removed its only for testing UI calls

		if (assetId) {
			this.props
				.deleteRendition(defaultAccountId, assetId, selectedRow.resourceId, renditionGroup, selectedRow.id)
				.then((res) => {
					if (res.message) {
						this.props.showNotification(res.message + selectedRow.name);
						return;
					}
					this.props.showNotification(t('COMMON_DELETED_SUCCESSFULLY') + ' ' + selectedRow.name);
					this.removeRenditionFromTable(selectedRow);
				});
		}

		if (selectedRow.type === renditionTypes.AUDIO) {
			this.props.removeAudio(defaultAccountId, assetId, selectedRow.resourceId).then((data) => {
				if (data && data.message) {
					this.props.showNotification(this.props.t('COMMON_FAILED_RESOURCE_DELETION') + ' ' + data.message);
					return;
				}

				/* setTimeout(function () {
                    window.location.reload()
                }, 2000) */
			});
		}

		if (selectedRow.type === renditionTypes.SUBTITLE) {
			this.props.removeSubtitle(defaultAccountId, assetId, selectedRow.resourceId).then((data) => {
				if (data && data.message) {
					this.props.showNotification(this.props.t('COMMON_FAILED_RESOURCE_DELETION') + ' ' + data.message);
					return;
				}
				/*
                this.fetchMediaDetails((details) => {
                    this.getData(details)
                })*/
			});
		}
		this.resetSelectedRow();
	};

	getRenditionGroup = (type) => {
		switch (type) {
			case renditionTypes.IMAGE:
				return renditionGroupTypes.IMAGES;
			case renditionTypes.VIDEO:
				return renditionGroupTypes.VIDEOS;
			case renditionTypes.AUDIO:
				return renditionGroupTypes.AUDIOS;
			case renditionTypes.INDEX:
				return renditionGroupTypes.INDEXES;
			case renditionTypes.SUBTITLE:
				return renditionGroupTypes.SUBTITLES;
			default:
				break;
		}
	};

	getTableDataWithType = (type) => {
		const { imageData, videoData, audioData, indexData, subtitleData } = this.state;
		let dataWithType = {
			table: [],
			stateTableName: '',
		};
		switch (type) {
			case renditionTypes.IMAGE:
				dataWithType['table'] = imageData;
				dataWithType['stateTableName'] = 'imageData';
				break;
			case renditionTypes.VIDEO:
				dataWithType['table'] = videoData;
				dataWithType['stateTableName'] = 'videoData';
				break;
			case renditionTypes.AUDIO:
				dataWithType['table'] = audioData;
				dataWithType['stateTableName'] = 'audioData';
				break;
			case renditionTypes.INDEX:
				dataWithType['table'] = indexData;
				dataWithType['stateTableName'] = 'indexData';
				break;
			case renditionTypes.SUBTITLE:
				dataWithType['table'] = subtitleData;
				dataWithType['stateTableName'] = 'subtitleData';
				break;
			default:
				break;
		}
		return dataWithType;
	};

	handleMenuOpen = (e, data) => {
		this.setState({
			menuAnchor: e.currentTarget,
			selectedRow: data,
		});
	};

	handleMenuClose = (e, data) => {
		this.setState({
			menuAnchor: null,
		});
		if (e.currentTarget.id) {
			if (e.currentTarget.id == 'delete') {
				this.handleDelete(data);
			}
		} else {
			this.resetSelectedRow();
		}
	};

	renderFileListMenu = () => {
		const { t } = this.props;
		const { menuAnchor, selectedRow } = this.state;
		if (menuAnchor) {
			return (
				<FileTableListMenu
					t={t}
					menuAnchor={menuAnchor}
					handleMenuClose={(e) => this.handleMenuClose(e, selectedRow)}
				/>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<>
				<div className="filesSection">
					{
						<>
							{this.getVideosTable()}
							{this.getImagesTable()}
							{this.getIndexTable()}
							{this.getAudiosTable()}
							{this.renderUploadAudioDialog()}
							{this.getSubtitleTable()}
							{this.renderInfoDialog()}
							{this.renderUploadSubtitleDialog()}
							{this.renderFileListMenu()}
							{this.renderDeleteDialog()}
						</>
					}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	isAudioUploadFinished: publish.isAudioUploadFinished,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	deleteRendition: (accId, assetId, resourceId, renditionGroup, renditionId) =>
		dispatch(deleteRendition(accId, assetId, resourceId, renditionGroup, renditionId)),
	startSubtitleUploadProcess: (accId, file, language, assetId, mediaId, resourceId) =>
		dispatch(startSubtitleUploadProcess(accId, file, language, assetId, mediaId, resourceId)),
	startAudioUploadProcess: (accId, file, language, assetId, mediaId, resourceId, audioDescription) =>
		dispatch(startAudioUploadProcess(accId, file, language, assetId, mediaId, resourceId, audioDescription)),
	removeSubtitle: (accountId, assetId, resourceId) => dispatch(removeSubtitle(accountId, assetId, resourceId)),
	removeAudio: (accountId, assetId, resourceId) => dispatch(removeAudio(accountId, assetId, resourceId)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	getAllCurrentJobs: (accountId) => dispatch(getAllCurrentJobs(accountId)),
	setUploadingProcessActive: (value) => dispatch(setUploadingProcessActive(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Files);
