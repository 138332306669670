import {
	deleteUser,
	createUser,
	updateUser,
	viewUserDetails,
	addUserToAccount,
	listAllUsersPerAccounts,
	getCustomMetadata,
	removeCustomMetadata,
	updateCustomMetadata,
	createCustomMetadata,
	listAllAccountsPerCustomers,
	_getAccountSettings,
	_updateAccountSettings,
	_createAccountSettings,
} from '../services/profileServices';
import { LocalStorageService } from '../services/localStorageService';

export const HAS_ADVANCED_SETTINGS = 'HAS_ADVANCED_SETTINGS';
export const SEND_UPDATE_ENABLED_EMBED = 'SEND_UPDATE_ENABLED_EMBED';
export const SEND_LOAD_USERS_REQUEST = 'SEND_LOAD_USERS_REQUEST';
export const SEND_LOAD_USERS_SUCCESS = 'SEND_LOAD_USERS_SUCCESS';
export const SEND_LOAD_USERS_FAILURE = 'SEND_LOGIN_FORM_FAILURE';
export const SEND_USER_DELETE_REQUEST = 'SEND_USER_DELETE_REQUEST';
export const SEND_USER_CREATE_REQUEST = 'SEND_USER_CREATE_REQUEST';
export const SEND_USER_DETAILS_REQUEST = 'SEND_USER_DETAILS_REQUEST';
export const SEND_USER_DETAILS_SUCCESS = 'SEND_USER_DETAILS_SUCCESS';
export const SEND_USER_DETAILS_FAILURE = 'SEND_USER_DETAILS_FAILURE';
export const PROFILE_NEW_USER_FORM_UPDATE = 'PROFILE_NEW_USER_FORM_UPDATE';
export const PROFILE_NEW_CUSTOM_PROPERTY_FORM_UPDATE = 'PROFILE_NEW_CUSTOM_PROPERTY_FORM_UPDATE';
export const SEND_UPDATE_USER_LIST_REQUEST = 'SEND_UPDATE_USER_LIST_REQUEST';
export const SEND_USER_DELETE_REQUEST_SUCCESS = 'SEND_USER_DELETE_REQUEST_SUCCESS';
export const SEND_USER_DELETE_REQUEST_FAILURE = 'SEND_USER_DELETE_REQUEST_FAILURE';
export const SEND_USER_CREATE_REQUEST_SUCCESS = 'SEND_USER_CREATE_REQUEST_SUCCESS';
export const SEND_USER_CREATE_REQUEST_FAILURE = 'SEND_USER_CREATE_REQUEST_FAILURE';
export const SEND_LOAD_CUSTOM_PROPERTIES_REQUEST = 'SEND_LOAD_CUSTOM_PROPERTIES_REQUEST';
export const SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_SUCCESS = 'SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_SUCCESS';
export const SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_FAILURE = 'SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_FAILURE';
export const SEND_DELETE_CUSTOM_PROPERTY_REQUEST = 'SEND_DELETE_CUSTOM_PROPERTY_REQUEST';
export const SEND_DELETE_CUSTOM_PROPERTY_REQUEST_SUCCESS = 'SEND_DELETE_CUSTOM_PROPERTY_REQUEST_SUCCESS';
export const SEND_DELETE_CUSTOM_PROPERTY_REQUEST_FAILURE = 'SEND_DELETE_CUSTOM_PROPERTY_REQUEST_FAILURE';
export const SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST = 'SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST';
export const SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS = 'SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS';
export const SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_FAILURE = 'SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_FAILURE';
export const SEND_CREATE_CUSTOM_PROPERTIES_REQUEST = 'SEND_CREATE_CUSTOM_PROPERTIES_REQUEST';
export const SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS = 'SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS';
export const SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_FAILURE = 'SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_FAILURE';
export const SEND_LOAD_CUSTOMER_ACCOUNTS_REQUEST = 'SEND_LOAD_CUSTOMER_ACCOUNTS_REQUEST';
export const SEND_LOAD_CUSTOMER_ACCOUNTS_SUCCESS = 'SEND_LOAD_CUSTOMER_ACCOUNTS_SUCCESS';
export const SEND_LOAD_CUSTOMER_ACCOUNTS_FAILURE = 'SEND_LOAD_CUSTOMER_ACCOUNTS_FAILURE';
export const SEND_USER_UPDATE_REQUEST = 'SEND_USER_UPDATE_REQUEST';
export const SEND_USER_UPDATE_REQUEST_SUCCESS = 'SEND_USER_UPDATE_REQUEST_SUCCESS';
export const SEND_USER_UPDATE_REQUEST_FAILURE = 'SEND_USER_UPDATE_REQUEST_FAILURE';
export const SEND_ADD_USER_TO_AN_ACCOUNT_REQUEST = 'SEND_ADD_USER_TO_AN_ACCOUNT_REQUEST';
export const SEND_ADD_USER_TO_AN_ACCOUNT_SUCCESS = 'SEND_ADD_USER_TO_AN_ACCOUNT_SUCCESS';
export const SEND_ADD_USER_TO_AN_ACCOUNT_FAILURE = 'SEND_ADD_USER_TO_AN_ACCOUNT_FAILURE';

export const profileNewUserFormUpdate = ({ field, value }) => ({
	type: PROFILE_NEW_USER_FORM_UPDATE,
	field,
	value,
});

export const profileNewCustomPropertyFormUpdate = ({ field, value }) => ({
	type: PROFILE_NEW_CUSTOM_PROPERTY_FORM_UPDATE,
	field,
	value,
});

export const setHasAdvancedSettings = (hasAdvancedSettings) => ({
	type: HAS_ADVANCED_SETTINGS,
	hasAdvancedSettings,
});

const sendUpdateEnabledEmbed = (enabledEmbed) => ({
	type: SEND_UPDATE_ENABLED_EMBED,
	enabledEmbed: enabledEmbed,
});

const sendLoadUsersRequest = (_) => ({
	type: SEND_LOAD_USERS_REQUEST,
});

const sendLoadUsersSuccess = (users) => ({
	type: SEND_LOAD_USERS_SUCCESS,
	users: users,
});

const sendLoadUsersFailure = (_) => ({
	type: SEND_LOAD_USERS_FAILURE,
});

const sendLoadCustomerAccountsRequest = (_) => ({
	type: SEND_LOAD_CUSTOMER_ACCOUNTS_REQUEST,
});

const sendLoadCustomerAccountsSuccess = (_) => ({
	type: SEND_LOAD_CUSTOMER_ACCOUNTS_SUCCESS,
});

const sendLoadCustomerAccountsFailure = (_) => ({
	type: SEND_LOAD_CUSTOMER_ACCOUNTS_FAILURE,
});

const userUpdateRequest = (_) => ({
	type: SEND_USER_UPDATE_REQUEST,
});

const userUpdateRequestSuccess = (_) => ({
	type: SEND_USER_UPDATE_REQUEST_SUCCESS,
});

const userUpdateRequestFailure = (_) => ({
	type: SEND_USER_UPDATE_REQUEST_FAILURE,
});

const sendUsersDetailsRequest = (_) => ({
	type: SEND_USER_DETAILS_SUCCESS,
});

const sendUsersDetailsSuccess = (_) => ({
	type: SEND_USER_DETAILS_SUCCESS,
});

const sendUsersDetailsFailure = (_) => ({
	type: SEND_USER_DETAILS_FAILURE,
});

const userDeleteReuqest = (_) => ({
	type: SEND_USER_DELETE_REQUEST,
});

const userDeleteReuqestSuccess = (_) => ({
	type: SEND_USER_DELETE_REQUEST_SUCCESS,
});

const userDeleteReuqestFailure = (_) => ({
	type: SEND_USER_DELETE_REQUEST_FAILURE,
});

const addUserToAccountRequest = (_) => ({
	type: SEND_ADD_USER_TO_AN_ACCOUNT_REQUEST,
});

const addUserToAccountRequestSuccess = (_) => ({
	type: SEND_ADD_USER_TO_AN_ACCOUNT_SUCCESS,
});

const addUserToAccountRequestFailure = (_) => ({
	type: SEND_ADD_USER_TO_AN_ACCOUNT_FAILURE,
});

const userCreateRequest = (_) => ({
	type: SEND_USER_CREATE_REQUEST,
});

const userCreateRequestSuccess = (_) => ({
	type: SEND_USER_CREATE_REQUEST_SUCCESS,
});

const createUserRequestFailure = (_) => ({
	type: SEND_USER_CREATE_REQUEST_FAILURE,
});

const sendLoadCustomPropertiesRequest = (_) => ({
	type: SEND_LOAD_CUSTOM_PROPERTIES_REQUEST,
});

const sendLoadCustomPropertiesRequestSuccess = (_) => ({
	type: SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_SUCCESS,
});

const sendLoadCustomPropertiesRequestFailure = (_) => ({
	type: SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_FAILURE,
});

const sendDeleteCustomPropertyRequest = (_) => ({
	type: SEND_DELETE_CUSTOM_PROPERTY_REQUEST,
});

const sendDeleteCustomPropertyRequestSuccess = (_) => ({
	type: SEND_DELETE_CUSTOM_PROPERTY_REQUEST_SUCCESS,
});

const sendDeleteCustomPropertyRequestFailure = (_) => ({
	type: SEND_DELETE_CUSTOM_PROPERTY_REQUEST_FAILURE,
});

const sendUpdateCustomPropertiesRequest = (_) => ({
	type: SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST,
});

const sendUpdateCustomPropertiesRequestSuccess = (_) => ({
	type: SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS,
});

const sendUpdateCustomPropertiesRequestFailure = (_) => ({
	type: SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_FAILURE,
});

const sendCreateCustomPropertiesRequest = (_) => ({
	type: SEND_CREATE_CUSTOM_PROPERTIES_REQUEST,
});

const sendCreateCustomPropertiesRequestSuccess = (_) => ({
	type: SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS,
});

const sendCreateCustomPropertiesRequestFailure = (_) => ({
	type: SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_FAILURE,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const listAllUsers = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendLoadUsersRequest());
	return listAllUsersPerAccounts(defaultAccountId)
		.then((data) => {
			dispatch(sendLoadUsersSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(sendLoadUsersFailure());
			return error;
		});
};

export const listCustomerAccounts = (customerId) => (dispatch) => {
	const defaultCustomerId = customerId;
	dispatch(sendLoadCustomerAccountsRequest());
	return listAllAccountsPerCustomers(defaultCustomerId)
		.then((data) => {
			dispatch(sendLoadCustomerAccountsSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendLoadCustomerAccountsFailure());
			return error;
		});
};

export const addUserToAnAccount = (accountId, data) => (dispatch) => {
	dispatch(addUserToAccountRequest());
	return addUserToAccount(accountId, data)
		.then((data) => {
			dispatch(addUserToAccountRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(addUserToAccountRequestFailure());
			return error;
		});
};

export const userDetails = (accountId, userId) => (dispatch) => {
	dispatch(sendUsersDetailsRequest());
	return viewUserDetails(accountId, userId)
		.then((data) => {
			dispatch(sendUsersDetailsSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendUsersDetailsFailure());
			return error;
		});
};

export const deleteUserFromAnAccount = (accountId, userId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(userDeleteReuqest());
	return deleteUser(defaultAccountId, userId)
		.then((data) => {
			dispatch(userDeleteReuqestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(userDeleteReuqestFailure());
			return error;
		});
};

export const userUpdate = (userId, data) => (dispatch) => {
	dispatch(userUpdateRequest());
	return updateUser(userId, data)
		.then((data) => {
			dispatch(userUpdateRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(userUpdateRequestFailure());
			return error;
		});
};

export const createNewUser = (email, ip) => (dispatch) => {
	dispatch(userCreateRequest());
	return createUser(email, ip)
		.then((data) => {
			dispatch(userCreateRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(createUserRequestFailure());
			return error;
		});
};

export const listCustomProperties = (accountId) => (dispatch) => {
	dispatch(sendLoadCustomPropertiesRequest());
	return getCustomMetadata(accountId)
		.then((data) => {
			dispatch(sendLoadCustomPropertiesRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendLoadCustomPropertiesRequestFailure());
			return error;
		});
};

export const removeCustomProperty = (accountId, nameInApi) => (dispatch) => {
	dispatch(sendDeleteCustomPropertyRequest());
	return removeCustomMetadata(accountId, nameInApi)
		.then((data) => {
			dispatch(sendDeleteCustomPropertyRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendDeleteCustomPropertyRequestFailure());
			return error;
		});
};

export const updateCustomProperties = (accountId, data) => (dispatch) => {
	dispatch(sendUpdateCustomPropertiesRequest());
	return updateCustomMetadata(accountId, data)
		.then((_) => {
			dispatch(sendUpdateCustomPropertiesRequestSuccess());
		})
		.catch((error) => {
			dispatch(sendUpdateCustomPropertiesRequestFailure());
			return error;
		});
};

export const createCustomProperties = (accountId, data) => (dispatch) => {
	dispatch(sendCreateCustomPropertiesRequest());
	return createCustomMetadata(accountId, data)
		.then((_) => {
			dispatch(sendCreateCustomPropertiesRequestSuccess());
		})
		.catch((error) => {
			dispatch(sendCreateCustomPropertiesRequestFailure());
			return error;
		});
};

export const getAccountSettings = (accountId) => (dispatch) => {
	return _getAccountSettings(accountId)
		.then((data) => {
			dispatch(userUpdateRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(userUpdateRequestFailure());
			return error;
		});
};

export const createAccountSettings = (accountId, data) => (dispatch) => {
	return _createAccountSettings(accountId, data)
		.then((data) => {
			dispatch(userUpdateRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(userUpdateRequestFailure());
			return error;
		});
};

export const updateAccountSettings = (accountId, data) => (dispatch) => {
	return _updateAccountSettings(accountId, data)
		.then((data) => {
			dispatch(userUpdateRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(userUpdateRequestFailure());
			return error;
		});
};

export const updateEnabledEmbed = (enabledEmbed) => (dispatch) => {
	dispatch(sendUpdateEnabledEmbed(enabledEmbed));
};
