import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Close, Done, PlayArrow } from '@material-ui/icons';
import _ from 'lodash';

import { setShowUploadMediasDialog } from '../../actions/uploadingActions';
import Popover from '../../reusable/Popover/Popover';
import { FLOWS, mediaNames } from '../../constants/mediaConstants';
import { loadMediasByQuery } from '../../actions/publishActions';

interface UploadStatusDialogProps {
	visible?: boolean;
	uploadingFiles?: any[];
	encodingFiles?: any[];
	finishedJobs?: any[];
	encodingGCFiles?: any[];
	finishedGCJobs?: any[];
	triggerNode: React.ReactNode;
	onClose: () => void;
	onClearCompletedFiles?: () => void;
	onOpenUploadStatusDialog?: () => void;
}

const UploadStatusDialog: React.FC<UploadStatusDialogProps> = ({
	visible,
	triggerNode,
	uploadingFiles = [],
	encodingFiles = [],
	finishedJobs = [],
	encodingGCFiles = [],
	finishedGCJobs = [],
	onOpenUploadStatusDialog,
	onClose,
	onClearCompletedFiles,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const defaultAccountId = useSelector((state: any) => state.session.defaultAccountId);
	const qbrickStandardFlows = useSelector((state) => (state as any).publish.qbrickStandardFlows);

	const [filesToShow, setFiles] = useState<any[]>([]);
	const [GCFilesToShow, setGCFiles] = useState<any[]>([]);

	const useQbrickStandardFlows = useMemo(
		() => qbrickStandardFlows.find((q: any) => q.id === FLOWS.qbrickStandard),
		[qbrickStandardFlows]
	);

	const onClearCompleteClick = useCallback(() => {
		setFiles((prevFiles) => {
			const newFileToShow = prevFiles.filter((pv) => !finishedJobs.find((f) => f.media_id === pv.media_id));
			return newFileToShow;
		});
		setGCFiles((prevFiles) => {
			const newGCFilesToShow = prevFiles.filter((pv) => !finishedGCJobs.find((f) => f.filename === pv.filename));
			return newGCFilesToShow;
		});
		onClearCompletedFiles?.();
	}, [onClearCompletedFiles, finishedJobs, finishedGCJobs]);

	const loadMediaWithTitle = useCallback(() => {
		if (!useQbrickStandardFlows) {
			return;
		}

		const currentFileIds = filesToShow.map((f) => f.media_id).filter(Boolean);
		const  currentFiles = [
			...uploadingFiles,
			...encodingFiles.filter((e) => !uploadingFiles.find((u) => u.media_id === e.media_id || u.replaceMediaId === e.media_id)),
		].filter(Boolean);
		const prevEncoding = filesToShow.filter((pv) => !currentFiles.find((c) => c.media_id === pv.media_id)).filter(f => {
			return !f.replaceMediaId || !currentFiles.find((c) => c.replaceMediaId === f.replaceMediaId);
		}).filter(Boolean);
		const newFilesToShow = [...currentFiles, ...prevEncoding];
		const mediaIds = newFilesToShow.map((f) => f.media_id).filter(Boolean);

		if (currentFileIds.join(',') === mediaIds.join(',')) {
			return;
		}

		if (mediaIds.length === 0 || newFilesToShow.every((f) => f.title)) {
			setFiles(newFilesToShow);
			return;
		}

		dispatch(
			loadMediasByQuery(
				defaultAccountId,
				mediaNames.medias,
				mediaIds.length === 1 ? `/${mediaIds[0]}` : '?q=id:(' + encodeURIComponent(mediaIds.join(',')) + ')'
			)
		).then((res: any) => {
			if (!res) {
				setFiles(newFilesToShow);
				return;
			}

			const medias = res?.map ? res : [res];
			const finalFileToShow = newFilesToShow.map((f) => {
				const mediaWithTitle = medias.find((m: any) => m.id === f.media_id);
				if (mediaWithTitle) {
					f.title = mediaWithTitle.metadata.title;
				}
				return f;
			});
			setFiles(finalFileToShow);
		});
	}, [defaultAccountId, dispatch, encodingFiles, filesToShow, uploadingFiles, useQbrickStandardFlows]);

	const getGCFilesToShow = useCallback(() => {
		const newEncodingGCFile = [
			...(!useQbrickStandardFlows ? uploadingFiles : []).filter(
				(u) =>
					!encodingGCFiles.find((e) => e.filename === u.filename) &&
					!finishedGCJobs.find((f) => f.filename === u.filename)
			),
			...encodingGCFiles,
		];
		setGCFiles(_.sortBy(newEncodingGCFile, 'filename'));
	}, [encodingGCFiles, uploadingFiles, useQbrickStandardFlows, finishedGCJobs]);

	useEffect(() => {
		loadMediaWithTitle();
		getGCFilesToShow();
	}, [loadMediaWithTitle, getGCFilesToShow]);

	const renderItem = ({
		fileName,
		isFinished,
		isEncoding,
		key,
	}: {
		fileName: string;
		isFinished: boolean;
		isEncoding: boolean;
		key?: string;
	}) => (
		<div key={key ?? fileName} className="eachSelectedItemWrapper--wrapper">
			<div className="eachSelectedItemWrapper">
				<div className="eachSelectedItemWrapper-label">
					<PlayArrow />
					<span className="fileNameUploaded">
						<label title={fileName}>
							{fileName
								? fileName.toString().length > 30
									? fileName.toString().substr(0, 30) + '...'
									: fileName.toString()
								: ''}
						</label>
					</span>
					<label className="fileNameUploaded--status">
						{isFinished ? 'Done' : isEncoding ? 'Encoding' : 'Preparing for Upload'}
					</label>
				</div>
				<div className="fileNameUploaded--status-icon">{isFinished && <Done />}</div>
			</div>

			{isFinished ? <LinearProgress variant="determinate" value={100} /> : <LinearProgress />}
		</div>
	);

	return (
		<Popover
			showArrow
			trigger="click"
			elevation={15}
			triggerNode={triggerNode}
			onTriggerClick={onOpenUploadStatusDialog}
			visible={visible}
		>
			{(filesToShow.length > 0 || GCFilesToShow.length > 0) && (
				<>
					<div className="dialogTitle" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h6 className="MuiTypography-root MuiTypography-h6" style={{ marginTop: '0' }}>
							{t('HEADER_UPLOAD_STATUS_DIALOG_UPLOAD_QUEUE_LABEL')}
						</h6>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className="dialogContentWrapper dialogStatusWrapper">
						<label>{t('COMMON_FILES')}</label>
						<div
							className="selectedItemsContentWrapper selectedItemsContentWrapperForStatusDialog"
							style={{ maxHeight: '500px' }}
						>
							{filesToShow.length > 0 &&
								filesToShow.map((eachFile) => {
									const fileName =
										eachFile.title ?? eachFile.filename ?? eachFile.name ?? eachFile.media_id;
									const isEncoding = encodingFiles.find((f) => (eachFile.replaceMediaId && eachFile.replaceMediaId === f.media_id) || eachFile.media_id === f.media_id);
									let isFinished = finishedJobs.find((f) => eachFile.media_id === f.media_id);
									isFinished = eachFile.replaceMediaId ? !isEncoding : isFinished;

									return renderItem({ fileName, isFinished, isEncoding, key: eachFile.media_id });
								})}

							{GCFilesToShow.length > 0 &&
								GCFilesToShow.map((eachFile) => {
									const fileName = eachFile.filename ?? eachFile.name;
									const isFinished = finishedGCJobs.find((f) => f.jobid === eachFile.jobid);
									const isEncoding = encodingGCFiles.find((f) => f.jobid === eachFile.jobid);
									return renderItem({ fileName, isFinished, isEncoding, key: eachFile.jobid });
								})}
						</div>

						{(filesToShow.length > encodingFiles.length ||
							GCFilesToShow.filter((s) => finishedGCJobs.find((f) => s.jobid === f.jobid)).length >
								0) && (
							<a onClick={onClearCompleteClick} className="hightlightedLink">
								{t('HEADER_UPLOAD_STATUS_DIALOG_UPLOAD_CLEAR_COMPLETE')}
							</a>
						)}
					</div>
					<div className="dialogActionsWrapper">
						<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
							<Button
								variant="contained"
								className="defaultActionBtn newUploadBtn"
								onClick={() => {
									onClose?.();
									dispatch(setShowUploadMediasDialog(true));
								}}
							>
								{t('HEADER_UPLOAD_STATUS_DIALOG_UPLOAD_NEW_BUTTON_LABEL')}
							</Button>
						</div>
					</div>
				</>
			)}
		</Popover>
	);
};

export default UploadStatusDialog;
