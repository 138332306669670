import { useState } from 'react';

export const SECTION_DEFAULT_THEMES = 'defaultThemes';
export const SECTION_ADVANCED_SETTINGS = 'advancedSettings';

const useExpendedSection = () => {
	const [expandedSection, setExpandSection] = useState<string[]>([SECTION_DEFAULT_THEMES, SECTION_ADVANCED_SETTINGS]);

	const updateExpandSection = (sectionId: string) => {
		if (expandedSection.includes(sectionId)) {
			setExpandSection((prevState: any) => prevState.filter((s: string) => s !== sectionId));
		} else {
			setExpandSection((prevState: any) => [...prevState, sectionId]);
		}
	};

	return { expandedSection, updateExpandSection };
};

export default useExpendedSection;
